

import { IUser } from "./Api/IUser";
import { useEffect, useState } from "react";
import { ContextMenuSavedReport } from "./ContextMenuSavedReport";
import { ContextMenuRecReport } from "./ContextMenuRecReport";
import { VadeFirebase } from "./Api/VadeFirebase";
import { IReportParams } from "./IReportParams";

interface DemandTabReportListTabProps {
    user:             IUser;
    savedReports:     IReportParams[];
    cloneSavedReport: ( filts: IReportParams ) => void;
    removeReport:     ( name:  string        ) => void;
}

const vadeFirebase = new VadeFirebase();

//this root tab of the analytics tab
export function DemandTabReportListTab( props: DemandTabReportListTabProps ) {
    const [recReports, setRecReports] = useState<IReportParams[]>( [] );

    useEffect( () => {
        vadeFirebase.getDepl( props.user.deploymentId ).then( resp => {
            const deplData = resp.data();
            if( !deplData?.recommendedReports ) {
                return;
            }
            const recReports: any[] = deplData.recommendedReports;
            const res = recReports.map( j => { return { //dates to not magically become dates
                ...j,
                startDate: j.startDate.toDate(),
                endDate:   j.endDate.toDate() }
            } );
            setRecReports( res );
        } );
    }, [] );

    return <div className="fullheight p-3 bg-offwhite" 
                style={{ display: "grid", 
                         gridTemplateColumns: "55fr 45fr",
                         gridTemplateRows: "1fr" }}>
        <div style={{ backgroundColor: "#ffffff" }} 
            className="fullheight rounded-7 me-3 p-5">
            <h3>
                <img src="/img/icon-save.svg" width="20" />
                &nbsp;Saved Reports
            </h3>
            <hr className="hr" />
            { props.savedReports.map( r => {
                return <div key={r.name}>
                    <span style={{ cursor: "pointer" }} onClick={ () => props.cloneSavedReport( r ) }>{r.name}</span>
                    <ContextMenuSavedReport
                        user={ props.user }
                        rename={ () => alert("not impl") } 
                        delete={ () => props.removeReport( r.name ) }
                        saveAsRecommended={ () => {
                            const recCopy = [...recReports, r];
                            vadeFirebase.saveRecReports( props.user.deploymentId, recCopy ).then( res => {
                                setRecReports( recCopy );
                            } );
                        } } />
                    <hr className="hr" />
                </div>;
            } ) }
        </div>
        <div style={{ backgroundColor: "#ffffff" }} 
            className="fullheight rounded-7 p-5">
            <h3>
                <img src="/img/vade-v.svg" width="20" />&nbsp;Recommended Reports
            </h3>
            <hr className="hr" />
            { recReports.map( r => {
                return <div key={r.name}>
                        <span style={{ cursor: "pointer" }} onClick={ () => props.cloneSavedReport( r ) }>{r.name}</span>
                        <ContextMenuRecReport
                            user={ props.user }
                            delete={ () => {
                                const filtered = recReports.filter( f => {
                                    return r !== f;
                                } );
                                vadeFirebase.saveRecReports( props.user.deploymentId, filtered ).then( res => {
                                    setRecReports( filtered );
                                } );                                
                            } } />
                        <hr className="hr" />
                    </div>;
            } ) }
        </div>
    </div>;
}
