import {Activity} from "./Activity";
import {TimeUnit} from "./IDeployment";
import {IRate, defaultRate} from "./IRate";
import {UserClass, VehicleType} from "./IUserClass";

//as specified in CDS
export interface IApiRule {
    activity:        Activity;    //parking, no parking, etc
    max_stay?:       number;      //if not there, then stay is unrestricted
    max_stay_unit?:  TimeUnit;    //defaults to minute
    no_return?:      number;      //if not there, then user may return immediately
    no_return_unit?: TimeUnit;    //defaults to minute
    user_classes?:   UserClass[]; //asdf
    rate?:         IRate[];     //I'm not allowing the rate for now
}


//I think these should go in the db...
//if not a required property it should be nullable
//encoding it into js
export interface IRule {
    activity:     Activity;
    max_stay?:    { dur:      number, unit: TimeUnit };
    no_return?:   { duration: number, unit: TimeUnit };
    user_classes: UserClass[];
    rate?:        IRate[];
}

export function convertToIRule( r: IApiRule ): IRule {
    return <IRule> {
      activity:  r.activity,
      max_stay:  r.max_stay  ? { dur: r.max_stay,       unit: r.max_stay_unit  || TimeUnit.Minute } : undefined,
      no_return: r.no_return ? { duration: r.no_return, unit: r.no_return_unit || TimeUnit.Minute } : undefined,
      user_classes: r.user_classes?.map(String) || [],
      rate: r.rate,
    };
}

export function convertToIApiRule( r: IRule ): IApiRule {
    return <IApiRule> {
        activity:  r.activity,
        max_stay:  r.max_stay?.dur,
        max_stay_unit: r.max_stay?.unit,
        no_return: r.no_return?.duration,
        no_return_unit: r.no_return?.unit,
        user_classes: (r.user_classes?.map(String)) || [],
        rate: r.rate,
    };
}

export function defaultRule(): IRule{
    return <IRule> {
        activity:  Activity.Parking,
        max_stay:  { dur: 120, unit: TimeUnit.Minute },
        no_return: { duration: 15, unit: TimeUnit.Minute },
        user_classes: [VehicleType.Car],
        rate: [defaultRate()],
    };
}