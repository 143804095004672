import {Dialog, DialogContent, DialogTitle, DialogActions} from "@mui/material";
import { useEffect, useState } from "react";
import { IPolicy } from "../../Api/IPolicy";
import { Options } from "../../Util/MeasureTypes";
import {Api} from "../../Api/Api";
import PolicyEditor from "./PolicyEditor";
import {MDBBtn} from "mdb-react-ui-kit";

export interface PolicyDialogProps {
    show:    boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    didCommit?:  (policy: IPolicy ) => void;
    policy: IPolicy;
}

export function PolicyEditorDialog( props: PolicyDialogProps ) {
    const {didCommit} = props
    console.log("prop policy: ", props.policy.name)
    const [policy,   setPolicy] = useState<IPolicy>(props.policy);
    if (props.policy.curbPolicyId !== policy.curbPolicyId) setPolicy(props.policy)  // Hopefully someone can keep this from being needed

    // useEffect( () => { //copy it if it changes while we are alive\
    //     console.log(`Edit Policy Changed ${policy.name}`)
    //     if( props.policy ) setPolicy( props.policy )
    // }, [policy.name, props.policy] );

    const close  = () => { props.setShow( false ); }
    const cancel = () => close();
    async function commitPolicyUpdate(){
        const result = await Api.updatePolicy(policy)
        if (result.ok){
            didCommit && didCommit( policy );
            close()
        }else{
            alert("Failed to update curbzone policy. Please try again. If it keeps failing contact help@vade.ai")
        }
    }

    return <Dialog key={policy.curbPolicyId} open={props.show} onClose={cancel} fullWidth={true} maxWidth="md">
        <DialogTitle>Edit: {policy.name}</DialogTitle>
        <DialogContent>
            <PolicyEditor startingPolicy={policy} onChange={setPolicy} />
            <DialogActions>
                <MDBBtn color="light" onClick={cancel}>Cancel</MDBBtn>
                <MDBBtn color="primary" onClick={commitPolicyUpdate}>Submit</MDBBtn>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

