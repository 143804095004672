import { Menu, MenuItem } from "@mui/material";
import { MDBIcon } from "mdb-react-ui-kit";
import { useState } from "react";
import { IUser } from "./Api/IUser";

export interface AppTabsUserMenuProps {
    user:        IUser;    
    createUser:  () => void;
    createDepl:  () => void;
    logout:      () => void;
}

export function AppTabsUserMenu( props: AppTabsUserMenuProps ) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const open                    = Boolean( anchorEl );
    const handleClick             = ( event: any ) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => { setAnchorEl( null ); };    
    let adminMenu = <></>;
    if( props.user.isAdmin ) {
        adminMenu = <>
            <MenuItem onClick={() => { props.createUser(); handleClose(); }}>Create User Record</MenuItem>
            <MenuItem onClick={() => { props.createDepl(); handleClose(); }}>Create Deployment Record</MenuItem>
        </>;
    }

    return <>
        <MDBIcon fas icon="bars"
            size="2x"
            style={{ cursor: "pointer" }}
            className="me-3"
            onClick={handleClick} />
        <Menu anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <div>
                {adminMenu}
                <MenuItem onClick={() => { props.logout(); handleClose(); }}>Logout</MenuItem>
            </div>
        </Menu>
    </>;
}
