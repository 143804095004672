import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { MDBBtn, MDBInputGroup, MDBSwitch } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { ICurbZone, ParkingAngle, StreetSides } from "../Api/IDeployment";
import { Options } from "../Util/MeasureTypes";
import { StrFld, AngleRadBtn, SideRadBtn, NumFld } from "./ZoneDialogUtil";

export interface ZoneDialogEditProps {
    show:    boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    commit:  ( cz: ICurbZone ) => void;
    zn:      undefined | ICurbZone;
}

export function ZoneDialogEdit( props: ZoneDialogEditProps ) {
    const [zn, setCz] = useState<ICurbZone>();

    useEffect( () => { //copy it if it changes while we are alive
        if( props.zn ) { setCz( props.zn ); }
    }, [props.zn] );

    const close = () => {
        props.setShow( false );
    }

    const commit = () => {
        close();
        if( !zn ) { return; }
        props.commit( zn );
    }
    
    const cancel = () => close();
    if( !zn ) {
        return <></>;
    }

    const set = ( obj: Options<ICurbZone> ) => { setCz( { ...zn, ...obj } ); }    
    return <Dialog key={zn.curb_zone_id} open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>Edit Zone</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Edit Zone Details
            </DialogContentText>
            <input style={{ display: "none" }} type="text" name="fakeusernameremembered"/>
            <input style={{ display: "none" }} type="password" name="fakepasswordremembered"/>
            <StrFld lbl="Zone Name"               val={zn.name}                    set={ v => set( { name: v } ) } />
            <StrFld lbl="User Zone Id"            val={zn.user_zone_id}            set={ v => set( { user_zone_id: v } ) } />
            <StrFld lbl="Street Name"             val={zn.street_name}             set={ v => set( { street_name: v } ) } />
            <StrFld lbl="Cross Street Start Name" val={zn.cross_street_start_name} set={ v => set( { cross_street_start_name: v } ) } />
            <StrFld lbl="Cross Street End Name"   val={zn.cross_street_end_name}   set={ v => set( { cross_street_end_name: v } ) } />
            <span>Parking Angle</span>
            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>    
                <AngleRadBtn lbl="Parallel"      angle={ParkingAngle.Parallel}      val={zn.parking_angle} set={ v => set( { parking_angle: v } ) } />
                <AngleRadBtn lbl="Perpendicular" angle={ParkingAngle.Perpendicular} val={zn.parking_angle} set={ v => set( { parking_angle: v } ) } />
                <AngleRadBtn lbl="Angled"        angle={ParkingAngle.Angled}        val={zn.parking_angle} set={ v => set( { parking_angle: v } ) } />
            </div>
            <span>Street Side</span>
            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>    
                {StreetSides.map( side => 
                    <SideRadBtn side={side}
                                key={side}
                                val={zn.street_side}
                                set={ v => set( { street_side: v } ) } /> ) }
            </div>
            <NumFld lbl="Number of Spaces" val={zn.num_spaces} set={ v => set( { num_spaces: v }) } />
            <MDBInputGroup>
                <MDBSwitch label="Curb Zone is in Median"
                           checked={zn.median}
                           color="danger"
                           onChange={( e ) => setCz( { ...zn, median: !zn.median } )}/>
            </MDBInputGroup>
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light" onClick={cancel}>Cancel</MDBBtn>
            <MDBBtn color="primary" onClick={commit}>Submit</MDBBtn>
        </DialogActions>
    </Dialog>
}