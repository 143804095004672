import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import { DateRangePicker } from "react-date-range";
import { reportActivityOptions } from "./ReportActivity";
import { DaysOfWeekCtrl } from "./Controls/DaysOfWeekCtrl";
import { TimeGrouping } from "./DomoUtil";
import { TimeRangeSlider } from "./Controls/PolicyUtils/TimespanControls/TimeSpanEditTable/TimeRangeSlider";
import { TimeRangeSliderUtil } from "./Controls/PolicyUtils/TimespanControls/TimeSpanEditTable/TimeRangeSliderUtil";
import { useState } from "react";
import { ReportTabParams } from "./IReportTabParams";
import { Alert } from "./Controls/Alert";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { DateUtil } from "./Util/DateUtil";
import { Dropdown as Dropdown } from "./Controls/Dropdown";
import { IZoneGroup } from "./IZoneGroup";
import { Prompt } from "./Controls/Prompt";
import { DemandTabFilterBarCtxMenu } from "./DemandTabReportTabFilterBarCtxMenu";

export interface DemandTabReportTabFilterBarProps {
    reportParams:          ReportTabParams;
    setReportParams:       ( id: number, attr: string, val: any ) => void;
    curbZoneGroups:        IZoneGroup[];
    showZoneEditor:        boolean;
    setShowZoneEditor:     React.Dispatch<React.SetStateAction<boolean>>;
    saveReport:            ( filts: ReportTabParams ) => string;
    exportStayRecs:        ( filts: ReportTabParams ) => string;
    exportChartRecs:       ( filts: ReportTabParams ) => string;
}

export function DemandTabReportTabFilterBar( props: DemandTabReportTabFilterBarProps ) {
    const [showRename, setShowRename] = useState<boolean>( false );
    const [showAlert,  setShowAlert]  = useState<boolean>( false );
    const [alertMsg,   setAlertMsg]   = useState<string> ( ""    );
    const [showCtx,    setShowCtx]    = useState<boolean>( false );

    const params = props.reportParams;
    function rangeLabel() {
        return DateUtil.toSmallDateStr( params.startDate ) + " - " + DateUtil.toSmallDateStr( params.endDate );
    }

    function dayRangeLabel() {
        let letters = params.daysOfWeek.map( d => d.substring( 0, 2 ) );
        if( letters.length === 0 ) {
            return "No Days Selected";
        }
        return letters.join( ", " ).toLowerCase();
    }

    function timeRangeLabel() {
        if( params.timeRange.length === 2 ) {
            return TimeRangeSliderUtil.dispHour( params.timeRange[0] ) +
                " - " + TimeRangeSliderUtil.dispHour( params.timeRange[1] );
        }
        return "Error!";
    }

    function set( attr: string, val: any ) {
        props.setReportParams( props.reportParams.id, attr, val );
    }

    function setDaysOfWeek( value: DayOfWeek[] ) {
        set( "daysOfWeek", value );
    }

    function setTimeRange( value: Array<number> ) {
        set( "timeRange", value );
    }

    const timeGrps = [TimeGrouping.ByDay, TimeGrouping.ByHour];
    return <div className="tab-demand-filter-bar gradient-custom">
        <div className="tab-demand-filter-bar-left">
            <div className="form-floating">
                <MDBDropdown>
                    <MDBDropdownToggle>{params.activity}</MDBDropdownToggle>
                    <MDBDropdownMenu>
                        {reportActivityOptions.map( activity =>
                            <MDBDropdownItem link key={activity} onClick={ e => set( "activity", activity )}>
                                {activity}
                            </MDBDropdownItem> )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <div className="form-floating">
                <MDBDropdown>
                    <MDBDropdownToggle>{params.timeGrp}</MDBDropdownToggle>
                    <MDBDropdownMenu>
                        {timeGrps.map( grp =>
                            <MDBDropdownItem link key={grp} onClick={ e => set( "timeGrp", grp ) }>
                                {grp}
                            </MDBDropdownItem> )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <div className="form-floating d-block">
                <MDBDropdown>
                    <MDBDropdownToggle>{params.curbZoneGrp.name}</MDBDropdownToggle>
                    <MDBDropdownMenu>
                        {props.curbZoneGroups.map( grp =>
                            <MDBDropdownItem link
                                             key={grp.name} 
                                             onClick={ e => set( "curbZoneGrp", grp )}>
                                {grp.name}
                            </MDBDropdownItem> )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={rangeLabel()} >
                    <DateRangePicker ranges={[{ startDate: params.startDate, endDate: params.endDate }]} 
                                        onChange={ r => {
                        const dr = r["range1"];
                        if( dr.startDate ) {
                            set( "startDate", dr.startDate );
                        }
                        if( dr.endDate ) {
                            set( "endDate", dr.endDate );
                        }
                    }} />
                </Dropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={dayRangeLabel()} 
                          style={{ minWidth: "16em" }}>
                              <div className="p-3 rounded-2 w-100" style={{ 
                                                            backgroundColor: "white",
                                                            filter:          "drop-shadow(15px 15px 15px #aaa7)", 
                                                            fontSize:        "0.875em" }}>
                        <div className="text-center">
                            <b>Days of Week</b>
                            <DaysOfWeekCtrl enabledDays={params.daysOfWeek} setEnabledDays={setDaysOfWeek} />    
                        </div>
                    </div>
                </Dropdown>
            </div>
            <div className="form-floating">
                <Dropdown label={timeRangeLabel()} style={{ minWidth: "12em" }}>
                    <div className="p-3 rounded-2 w-100" style={{ 
                                                            backgroundColor: "white",
                                                            filter:          "drop-shadow(15px 15px 15px #aaa7)", 
                                                            fontSize:        "0.875em" }}>
                        <div className="text-center">
                            <b>Time Range</b>
                        </div>
                        <TimeRangeSlider
                            timeRange={params.timeRange}
                            setTimeRange={setTimeRange} />
                    </div>
                </Dropdown>
            </div>
        </div>
        <div className="mt-2 me-2" 
             style={{ display:        "flex", 
                      justifyContent: "end", 
                      alignItems:     "top",
                      gap:            "0.25em" }}>            
            <MDBIcon fas icon="save" size="2x" color="body"
                style={{ cursor: "pointer", height: 30, width: 30 }}
                onClick={ () => {
                    const msg = props.saveReport( props.reportParams );
                    setAlertMsg( msg );
                    setShowAlert( true );
                }} />
            <Alert
                show={showAlert}
                setShow={setShowAlert}
                title="Report Saved"
                label={alertMsg}
                confirmLabel="Close"
                commit={ () => {} } />            
            <DemandTabFilterBarCtxMenu 
                exportStats={  () => props.exportChartRecs( props.reportParams ) }
                exportStays={  () => props.exportStayRecs ( props.reportParams ) }
                renameReport={ () => setShowRename( true ) } />
            <Prompt
                title="Rename Report"
                label="Rename Report"
                initialValue={params.name}
                show={showRename}
                setShow={setShowRename}
                commit={ (newName: string) => set( "name", newName ) } />
        </div>
    </div>;
}
