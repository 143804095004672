import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export function enumToOption<T>(enumValue: T): { label: string; value: T } {
    return {
        label: enumValue as unknown as string,
        value: enumValue,
    };
}
export function enumToOptions<T>(enumList: T[]): { label: string; value: T }[] {
    return enumList.map((enumValue) => ({
        label: enumValue as unknown as string,
        value: enumValue,
    }));
}

type TrashButtonProps = {
    onClick: () => void;
    children?: React.ReactNode;
};
export const TrashButton = ({ onClick, children }: TrashButtonProps) => {
    const buttonStyle = {
        backgroundColor: '#f2f2f2',
        borderRadius: '5px',
        padding: '5px',
        width: '40px',
        height: '40px',
        minWidth: 'unset',
        minHeight: 'unset',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: 'rgba(255,0,0,0.78)',
        },
    };

    const iconStyle = {
        color: '#555555',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '6px',
    };

    return (
        <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<DeleteIcon sx={iconStyle} />}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};


type AddButtonProps = {
    onClick: () => void;
    children?: React.ReactNode;
    sx?: SxProps<Theme>
};

export const AddButton = ({ onClick, children, sx }: AddButtonProps) => {
    const buttonStyle = {
        backgroundColor: 'system.primary',
        borderRadius: '3px',
        padding: '3px',
        width: '40px',
        height: '40px',
        minWidth: 'unset',
        minHeight: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s',
        ...sx
    };

    const iconStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '6px',
    };

    return (
        <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<AddIcon sx={iconStyle} />}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};


interface CircleProps {
    color: string;
    size: number;
}

export const Circle: React.FC<CircleProps> = ({ color, size }) => {
    const circleStyle = {
        backgroundColor: color,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
    };

    return <div style={circleStyle}></div>;
};



type EditButtonProps = {
    onClick: () => void;
    children?: React.ReactNode;
};

export const EditButton = ({ onClick, children }: EditButtonProps) => {
    const buttonStyle = {
        backgroundColor: '#f2f2f2',
        borderRadius: '5px',
        padding: '5px',
        width: '40px',
        height: '40px',
        minWidth: 'unset',
        minHeight: 'unset',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 255, 0.78)',
        },
    };

    const iconStyle = {
        color: '#555555',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '6px',
    };

    return (
        <Button
            sx={buttonStyle}
            variant="contained"
            startIcon={<EditIcon sx={iconStyle} />}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

