import { MDBSwitch } from "mdb-react-ui-kit";
import { SupplyTabMapTool } from "./SupplyTabMapTool";
import { SupplyTabMapToolMenu } from "./SupplyTabMapToolMenu";
import { Setter } from "./Util/MeasureTypes";

export interface SupplyTabMapToolbarProps {
    showCams:    boolean;
    setShowCams: Setter<boolean>;
    tool:        SupplyTabMapTool;
    setTool:     Setter<SupplyTabMapTool>;
}

export function SupplyTabMapToolbar( { showCams,
                                       setShowCams,
                                       tool,
                                       setTool
                                    }: SupplyTabMapToolbarProps ) {
    return <div className="position-absolute m-2"
                style={{ zIndex: 0, display: "flex", flexDirection: "row", gap: "1em" }}>
        <div className="alert-primary rounded-5 px-3 py-2"
             style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div>
                <img src="/img/map-pin-modern-vade-v-black.svg" width={20} />&nbsp;&nbsp;Show Cams &nbsp;
            </div>
            <div>
                <MDBSwitch checked={showCams} onChange={ e => setShowCams( !showCams ) } />
            </div>
        </div>
        <SupplyTabMapToolMenu tool={tool} setTool={setTool} />
    </div>;
}