import { TextField } from "@mui/material";
import { MDBRadio } from "mdb-react-ui-kit";
import { ParkingAngle, StreetSide } from "../Api/IDeployment";
import { Action } from "../Util/MeasureTypes";

export interface StrFldProps {
    lbl: string;
    val: string;
    set: Action<string>;
}

export function StrFld( props: StrFldProps ) {
    const {lbl, val: value, set } = { ...props };
    return <TextField
                fullWidth
                margin    ="dense"
                label     ={lbl}
                type      ="text"
                variant   ="standard"
                value     ={value}
                inputProps={{ autoComplete: "new-zone-name" }}
                onKeyDown ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onClick={(e) => e.stopPropagation()}
                onChange={ (e) => set( e.target.value ) } />
}

export interface NumFldProps {    
    lbl: string;
    val?: number;
    set?: Action<number>;
    opSet?: Action<number | undefined>
}

export function NumFld( props: NumFldProps ) {
    const {lbl, val: value, set, opSet } = { ...props };

    function cleanSet(input: string){
        const valAsInt = parseInt(input)
        if (opSet){
            if (isNaN(valAsInt) ) opSet(undefined)
            else opSet(valAsInt)
            return
        }else{
            if (valAsInt){
                set && set(valAsInt)
            }
        }
    }

    return <TextField
                fullWidth
                margin    ="dense"
                label     ={lbl}
                type      ="number"
                variant   ="standard"
                value     ={value}
                inputProps={{ autoComplete: "new-zone-name" }}
                onKeyDown ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onClick={(e) => e.stopPropagation()}
                onChange={ (e) => cleanSet(e.target.value) } />
}


export interface AngleRadBtnProps {
    lbl:   string;
    angle: ParkingAngle;
    val:   ParkingAngle;
    set:   Action<ParkingAngle>;
}

export function AngleRadBtn( props: AngleRadBtnProps ) {    
    const checked  = props.val === props.angle;
    return <MDBRadio checked={ checked }
                     onChange={ () => { props.set( props.angle ) }} 
                     label={props.lbl} />
}

export interface SideRadBtnProps {
    side: StreetSide;
    val:  StreetSide;
    set:  Action<StreetSide>;
}

export function SideRadBtn( props: SideRadBtnProps ) {
    const checked  = props.val === props.side;
    return <MDBRadio checked={ checked }
                     onChange={ () => { props.set( props.side ) } }
                     label={props.side} />
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}