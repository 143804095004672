import React from 'react';
import {
    TableContainer,
    TableBody,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Button, Badge
} from '@mui/material';
import Select from 'react-select';
import {Activity} from "../../../Api/Activity";
import {defaultRule, IRule} from "../../../Api/IRule";
import {allUserClassesAsSelectOptions, UserClass} from "../../../Api/IUserClass";
import {AddButton, enumToOption, enumToOptions, TrashButton} from "../../../Util/UIHelpers";
import {Options} from "../../../Util/MeasureTypes";
import Box from "@mui/material/Box";
import {RateEditTable} from "../RateControls/RateEditTable";


interface RuleEditTableProps {
    rules: IRule[];
    rulesDidChange?: (newRules: IRule[], idx: number) => void
}

const RuleEditTable: React.FC<RuleEditTableProps> = ({ rules , rulesDidChange}) => {

    const activityOptions: {label: string; value: Activity }[] = enumToOptions(Object.values(Activity));
    const userClassOptions: {label: string, options: {label: string, value: UserClass}[]}[] =  allUserClassesAsSelectOptions();

    const updateRule = (options: Options<IRule>, idx: number) => {
        let newRules = [...rules]
        const currRule = newRules[idx]
        newRules[idx] = {...currRule, ...options}
        rulesDidChange && rulesDidChange(newRules, idx)
    }

    const handleActivityChange = (activity: Activity | undefined, idx: number) => {
        // Update the corresponding rule's activity in the list of rules
        if (!activity) return
        updateRule({activity: activity}, idx)
    };

    const handleUserClassesChange = (selectedOptions: {label: string; value: UserClass }[], idx: number) => {
        const selectedClasses = selectedOptions.map((option) => option.value)
        updateRule({user_classes: selectedClasses}, idx)
    };

    const handleRateChanges = (newRates: any, idx: number) => {
        updateRule({rate: newRates}, idx)
    };

    const onAddRule = () => {
        let newRule = defaultRule()
        let newRules = [...rules, newRule]
        rulesDidChange && rulesDidChange(newRules, rules.length)
    }

    const onDeleteRule = (idx: number) => {
        let oldRules = [...rules]
        oldRules.splice(idx, 1);
        rulesDidChange && rulesDidChange(oldRules, idx)
    }

    return (
        <div>
            <Box sx={{ border: 1 , borderColor: 'lightgrey', borderRadius: '16px'}}>
                <TableContainer>
                    <Table>
                        {/*<TableHead>*/}
                        <TableRow>
                            <TableCell style={{ minWidth: 200, maxWidth: 250 }}>Activity     </TableCell>
                            <TableCell style={{ minWidth: 200, maxWidth: 250 }}>User Classes </TableCell>
                            <TableCell style={{ minWidth: 200 }}               >Rates        </TableCell>
                            <TableCell> <AddButton onClick={onAddRule}/>                     </TableCell>
                        </TableRow>
                        {/*</TableHead>*/}
                        <TableBody>
                            {rules.map((rule, idx) => (
                                <TableRow key={`rule_cell_${idx}`}>
                                    <TableCell>
                                        <Select
                                            isSearchable={true}
                                            options={activityOptions}
                                            value={enumToOption(rule.activity)}
                                            onChange={(val) => handleActivityChange(val?.value, idx)}
                                            menuPortalTarget={document.body}  // these 2 lines needed to make the options show over shit
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9990 }) }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            isMulti
                                            isSearchable={true}
                                            options={userClassOptions}
                                            value={enumToOptions(rule.user_classes ?? [])}
                                            onChange={(newVals) => handleUserClassesChange(Array.from(newVals), idx)}
                                            menuPortalTarget={document.body}  // these 2 lines needed to make the options show over shit
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9990 }) }}  // ^
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RateEditTable rule={rule} ratesDidChange={(newRates, _) => handleRateChanges(newRates, idx)} />
                                    </TableCell>
                                    <TableCell>
                                        <TrashButton onClick={() => onDeleteRule(idx)} />
                                    </TableCell> {/* Delete button */}
                                </TableRow>
                                
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default RuleEditTable;
