
export enum CurbZoneGroupType {
    All    = "All",
    Custom = "Custom"
}

export type IZoneGroup = {
    type:      CurbZoneGroupType,
    name:      string,
    curbZones: string[]; //list of ids
}
