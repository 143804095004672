import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Switch } from "@mui/material";
import { MDBBtn, MDBCheckbox, MDBSwitch } from "mdb-react-ui-kit";
import { useState } from "react";

interface CreateUserDialogProps {
    show:         boolean;
    setShow:      React.Dispatch<React.SetStateAction<boolean>>;
    commit:       ( email: string, password: string, deplId: string, isAdmin: boolean ) => void;
    deploymentId: string;
}

export function CreateUserDialog( props: CreateUserDialogProps ) {
    const [email,    setEmail]    = useState( "" );
    const [password, setPassword] = useState( "" );
    const [deplId,   setDeplId]   = useState( props.deploymentId );
    const [isAdmin,  setIsAdmin]  = useState( false );

    const close = () => {
        props.setShow( false );
        setEmail     ( "" );
        setPassword  ( "" );
    }

    const commit = () => {
        close();
        props.commit( email, password, deplId, isAdmin );
    }
    
    const cancel = () => close();

    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>Create User Record</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This doesn't add the user to Firebase Authentication.
                This just adds their user record to Firestore.
            </DialogContentText>
            <input style={{ display: "none"}} type="text"     name="fakeusernameremembered" />
            <input style={{ display: "none"}} type="password" name="fakepasswordremembered" />
            <TextField
                fullWidth
                margin    ="dense"
                label     ="Email Address"
                type      ="text"
                value     ={email}
                inputProps={{ autoComplete: "new-username" }}
                onKeyDown ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onChange={ (e) => setEmail( e.target.value ) } />
            <TextField
                fullWidth
                margin      ="dense"
                label       ="Deployment Id"
                type        ="text"
                value       ={deplId}
                autoComplete="none"
                inputProps  ={{ autoComplete: "new-deplId" }}
                onKeyDown   ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onChange={ (e) => setDeplId( e.target.value ) } />
                <MDBCheckbox label="Is Admin"
                             checked={isAdmin}
                             color="danger"
                             onChange={ (e) => setIsAdmin( !isAdmin ) } />
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light"   onClick={cancel}>Cancel</MDBBtn>
            <MDBBtn color="primary" onClick={commit}>Add User</MDBBtn>
        </DialogActions>
    </Dialog>
}