import { Menu, MenuItem } from "@mui/material";
import { MDBIcon } from "mdb-react-ui-kit";
import { useState       } from "react";

export interface DemandTabFilterBarCtxMenuProps {
    exportStats:  () => void;
    exportStays:  () => void;
    renameReport: () => void;
}

export function DemandTabFilterBarCtxMenu( props: DemandTabFilterBarCtxMenuProps ) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const open                    = Boolean( anchorEl );
    const handleClick             = ( event: any ) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => { setAnchorEl( null ); };    

    return <>
        <MDBIcon fas icon="caret-square-down"
            size="2x"
            style={{ cursor: "pointer", height: 30, width: 30 }}
            className="float-end"
            onClick={handleClick} />
        <Menu anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <div>                
                <MenuItem onClick={() => { props.exportStats(); handleClose(); }}>Download Report Data</MenuItem>
                <MenuItem onClick={() => { props.exportStays(); handleClose(); }}>Download Stay Data</MenuItem>
                <MenuItem onClick={() => { props.renameReport(); handleClose(); }}>Rename Report</MenuItem>
            </div>
        </Menu>
    </>;
}
