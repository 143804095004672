import { ICurbZone } from "./Api/IDeployment";
import { RealtimeDatum } from "./Api/Realtime";

export interface RealtimeEntry extends RealtimeDatum {
    value: number;
    color: string;
    units: string;
}

export function realtimeOccFraction( cz: ICurbZone, realtimeData: RealtimeEntry[] ) {
    for( var czData of realtimeData ) {
        if( cz.curb_zone_type === 'parking_lot' ) {
            if( czData.curb_zone_id === cz.curb_zone_id ) {
                let totalSpacesInLot   = czData.total_spaces_in_lot;
                let totalSpacesCovered = czData.total_covered_spaces;
                let openSpaces         = czData.open_spaces;
                return `${openSpaces} / ${totalSpacesCovered} avail.`;
            }
        }
    }
    return "";
}

export function colorize( els: RealtimeEntry[] ): RealtimeEntry[] {
    const min     = Math.min( ...els.map( el => el.occupied_percentage ) );
    const max     = Math.max( ...els.map( el => el.occupied_percentage ) );
    const spread  = Math.abs( min - max ); //get total range of values
    const incr    = spread / 3.0;          //div into 5 partitions
    const buckets: number[] = [];
    for( let i = 1; i <= 5; i++ ) {
        buckets.push( min + (incr * i) );
    }
    const colors  = [ "#00CE08", "#F5EC00", "#E31A1C"];
    const res = els.map( el => {
        const bucket = buckets.findIndex( b => el.occupied_percentage <= b ); 
        if( bucket === -1 ) {
            console.error( "couldn't colorize" );
        }
        //console.log( "gave " + el.curb_zone_id + " with v = " + el.value + " a colorization of " + colors[bucket] );
        el.color = colors[bucket];
        return el;
    } );
    return res;
}

