import { ApiUrl } from "./ApiUrl";
import { LineStringGeometry, INewZone, ICurbZone, ParkingAngle, StreetSide } from "./IDeployment";
import {convertToIAPIPolicy, IApiPolicy, IPolicy} from "./IPolicy";
import {convertToIApiRule} from "./IRule";
import {convertToIApiTimeSpan} from "./ITimeSpan";

interface IApiCreateZone extends INewZone {
    deployment_uuid: string;
}

type IApiUpdateZone = {
    curb_zone_id:            string;
    name:                    string;    
    user_zone_id:            string;
    street_name:             string;
    cross_street_start_name: string;
    cross_street_end_name:   string;
    parking_angle:           ParkingAngle;
    street_side:             StreetSide;
    num_spaces:              number;
    median:                  boolean;
    curb_policy_ids:        string[];
};

// Generated by https://quicktype.io

export interface IAPIUpdateZoneResp {
    curb_zone:          ICurbZone;
    updated_properties: string[];
}

export interface Geometry {
    coordinates: Array<Array<number[]>>;
    type:        string;
}

export interface GISLine {
    coordinates: Array<number[]>;
    type:        string;
}


export class Api {
    private static postArgs( payload: any, apiKey?: string) {
        let headers: any = { 'Content-Type': 'application/json' }
        if (apiKey) headers["api_key"] = apiKey
        return {
            method: 'POST',
            headers: headers,
            body:    JSON.stringify( payload ) };
    }

    static getDeployment( deplId: string ) {
        return fetch( ApiUrl.deploymentDetails( deplId ) );
    }
    static getRealtimeData( deplId: string ) {
        const payload = {
            api_key:         "realtime-0109820938029832",
            deployment_uuid: deplId,
        }
        return fetch( ApiUrl.realtimeData(), { 
            method:  "POST",
            headers: { "Content-Type": "application/json" },
            body:    JSON.stringify( payload )
        } );
    }

    static createZone( deplId: string, zone: INewZone ) {
        let payload: IApiCreateZone = { deployment_uuid: deplId, ...zone };
        return fetch( ApiUrl.curbZoneCreate(), this.postArgs( payload ) );
    }
    
    static updateCurbZoneGeometry( zoneId: string,
                                   line:   LineStringGeometry ) {
        let payload = {
            curb_zone_id:    zoneId,
            gis_line:        line
        };
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

    static prepareUpdateObj = ( zn: ICurbZone): IApiUpdateZone => {
        return {
            curb_zone_id:            zn.curb_zone_id,
            name:                    zn.name,
            user_zone_id:            zn.user_zone_id,
            street_name:             zn.street_name,
            cross_street_start_name: zn.cross_street_start_name,
            cross_street_end_name:   zn.cross_street_end_name,
            parking_angle:           zn.parking_angle,
            street_side:             zn.street_side,
            num_spaces:              zn.num_spaces,
            median:                  zn.median,
            curb_policy_ids:         zn.curb_policy_ids
        };
    }

    static updateZone( zone: ICurbZone ) {
        let payload = this.prepareUpdateObj( zone );
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

     static archiveZone( zoneId: string, archived: boolean = true ) {
        let payload = {
            curb_zone_id: zoneId,
            archived:  archived
        };
        return fetch( ApiUrl.curbZoneUpdate(), this.postArgs( payload ) );
    }

    static createPolicy(policy: IPolicy){
        let payload: IApiPolicy = convertToIAPIPolicy(policy)
        return fetch( ApiUrl.policyCreate(), this.postArgs( payload, "VADE_01029830198329748798719187" ) );
    }

    static updatePolicy(policy: IPolicy){
        const vadePolicy = {
            curb_policy_id: policy.curbPolicyId,
            priority:   policy.priority,
            rules:      policy.rules.map(convertToIApiRule),
            time_spans: policy.timeSpans.map(convertToIApiTimeSpan),
            name:       policy.name
        };
        return fetch( ApiUrl.policyUpdate(), this.postArgs( vadePolicy, "VADE_01029830198329748798719187" ) );
    }

    static archivePolicy(policyId: string, force: boolean = false){
        let payload = {
            policy_id: policyId,
            force_archive: force
        }
        return fetch( ApiUrl.policyArchive(), this.postArgs( payload, "VADE_01029830198329748798719187" ) );
    }
}

