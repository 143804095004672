import { DomoQueryResp } from "./DemandStayData";

export function download( filename: string, text: string ) {
    var el = document.createElement( 'a' );
    el.setAttribute( 'href', 'data:text/plain;charset=utf-8,' + encodeURIComponent( text ) );
    el.setAttribute( 'download', filename );
    el.style.display = 'none';
    document.body.appendChild( el );
    el.click();
    document.body.removeChild( el );
}

//currently used version
export function encodeCsv( csvRows: Array<Array<string>> ) {
    //puts all values in double quotes to allow them to contain commas and double quotes
    //also escapes all " to ""
    const escRows  = csvRows.map( r => r.map( el => `"${el.replaceAll( '"', '""' )}"` ) );
    const colSmash = escRows.map( r => r.reduce( ( a, b ) => `${a},${b}` ) );
    const rowSmash = colSmash.reduce( ( a, b ) => a + "\r\n" + b );
    return rowSmash;
}

export function exportDomoQuery( data: DomoQueryResp, filename: string ) {
    const cols: Array<string> = data.columns;
    const csvRows: Array<Array<string>> = [cols];
    for( let i = 0; i < data.rows.length; i++ ) {
        let row = data.rows[i];
        csvRows.push( row.map( field => field.toString() ) );
    }
    download( filename, encodeCsv( csvRows ) );
}