import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBInput, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit"
import { useEffect, useState } from "react";
import { DemandTabReportTab } from "./DemandTabReportTab"
import { IDepl } from "./Api/IDeployment";
import { IZoneGroup } from "./IZoneGroup";
import { Prompt } from "./Controls/Prompt";
import { ExitBtn } from "./Controls/ExitBtn";
import { getDefaultReport, IReportParams } from "./IReportParams";
import { DemandTabReportListTab } from "./DemandTabReportListTab";
import { ReportTabParams } from "./IReportTabParams";
import { IUser } from "./Api/IUser";
import { VadeFirebase } from "./Api/VadeFirebase";

interface DemandTabProps {
    user:                IUser;
    depl:                IDepl;
    curbZoneGroups:      IZoneGroup[];
    updateCurbZoneGroup: ( cz: IZoneGroup ) => void;
}

const vadeFirebase = new VadeFirebase();

export function DemandTab( props: DemandTabProps ) {
    const [reports,      setReports     ] = useState<ReportTabParams[]>( [] );
    const [activeTab,    setActiveTab   ] = useState<number>( 0 );
    const [showPrompt,   setShowPrompt  ] = useState<boolean>( false );
    const [savedReports, setSavedReports] = useState<IReportParams[]>( [] );

    function loadSavedReports() {
        vadeFirebase.getDepl( props.user.deploymentId ).then( resp => {
            const deplData = resp.data();
            if( !deplData?.savedReports ) {
                return;
            }
            const savedReports: any[] = deplData.savedReports;
            const res = savedReports.map( rep => { 
                delete rep?.id;
                return { //dates to not magically become dates
                ...rep,
                startDate: rep.startDate.toDate(),
                endDate:   rep.endDate.toDate() }
            } );
            setSavedReports( res );
        } );
    }

    useEffect( () => {
        loadSavedReports();
    }, [] );

    function setReportParams( id: number, attr: string, val: any ) {
        setReports( prev => prev.map( r => {
            if( r.id === id ) {
                return { ...r, [attr]: val };
            }
            return r;
        } ) );
    }

    function removeReport( name: string ) {
        const savedReps = savedReports.filter( r => r.name !== name );
        setSavedReports(  savedReps );
        saveSavedReports( savedReps );
    }

    function saveSavedReports( savedReports: IReportParams[] ): void {
        const prepared = savedReports.map( ( rep: any ) => {
            delete rep?.id;
            return rep;
        } )
        vadeFirebase.saveSavedReports( props.user.deploymentId, prepared );
    }

    function saveReport( savedReport: IReportParams ) {
        const ind = savedReports.findIndex( ur => ur.name === savedReport.name );
        if( ind === -1 ) {
            let mod = [...savedReports, savedReport];
            setSavedReports ( mod );
            saveSavedReports( mod );
            return `Saved New Report: '${savedReport.name}'`;
        }
        const mod = [...savedReports];
        mod[ind]  = { ...mod[ind], ...savedReport };
        setSavedReports ( mod );
        saveSavedReports( mod );
        return `Saved Report: '${savedReport.name}'`;
    }

    const handleTabClick = ( value: number ) => {
        if( value === activeTab ) {
            return;
        }
        setActiveTab( value );
    };

    function showNewReportPrompt() {
        setShowPrompt( true );
    }

    function createNewReportTab( name: string ) {
        let newRep = getDefaultReport( props.depl.extras.defaultFilterStartDate,
                                       props.depl.extras.defaultFilterEndDate    );
        newRep.name = name;
        setReports( [ ...reports, { ...newRep, id: reports.length } ] );
        setActiveTab( tabInd( reports.length ) );
    }

    function closeReportTab( deleteInd: number ) {
        setReports( [ ...reports.filter( ( r, i ) => deleteInd !== tabInd( i ) ) ] );
        setActiveTab( reports.length - 1 );
    }

    function tabInd( rawInd: number ) {
        return rawInd + 1;
    }

    function cloneSavedReport( filts: IReportParams ) {
        setReports( [ ...reports, { ...filts, id: reports.length } ] );
        setActiveTab( tabInd( reports.length ) );
    }

    return <div className="tab-demand">
        <MDBTabs className="fullheight">
            <MDBTabsItem>
                <MDBTabsLink onClick={ () => handleTabClick( 0 ) } 
                             active={ activeTab === 0 }>
                    <img src="/img/icon-report.svg" 
                         width="14" />
                </MDBTabsLink>
            </MDBTabsItem>
            { reports.map( ( r, i ) => <MDBTabsItem>
                <MDBTabsLink onClick={ () => handleTabClick( tabInd( i ) ) } 
                             active={ activeTab === tabInd( i ) }>
                    &nbsp; { r.name } &nbsp;
                   <ExitBtn 
                        onClick={ () => closeReportTab( tabInd( i ) ) } />
                </MDBTabsLink>
            </MDBTabsItem> ) }
            <MDBTabsItem>
                <Prompt
                        title="Create New Report"
                        label="New Report Name"
                        show={showPrompt}
                        setShow={setShowPrompt} 
                        commit={createNewReportTab} />
                <MDBTabsLink onClick={ () => showNewReportPrompt() }>
                    New Report
                </MDBTabsLink>
            </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent className="fullheight cory-tab">
            <MDBTabsPane show={ activeTab === 0 } className="fullheight">
                <DemandTabReportListTab
                    user={props.user}
                    savedReports={savedReports}
                    cloneSavedReport={cloneSavedReport} 
                    removeReport={removeReport} />
            </MDBTabsPane>
            {reports.map( ( r, i ) => {
                return <MDBTabsPane className="fullheight cory-tab" show={activeTab === tabInd( i )}>
                    <DemandTabReportTab
                        user={props.user}
                        depl={props.depl}
                        curbZoneGroups={props.curbZoneGroups}
                        updateCurbZoneGroup={props.updateCurbZoneGroup}
                        reportParams={r}
                        setReportParams={setReportParams}
                        saveReport={saveReport} />
                </MDBTabsPane>; } ) }
        </MDBTabsContent>
    </div>;
}

