import { DateTime } from "luxon";
import { IDomoAuth, DomoApi } from "./Api/DomoApi";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { CurbZoneGroupType, IZoneGroup } from "./IZoneGroup";

const laneTypeConv = `CASE lane_type
WHEN 'all'            THEN 'All'
WHEN 'curb_parking'   THEN 'Curb Parking'
WHEN 'bike_lane'      THEN 'Bike Lane'
WHEN 'no_parking'     THEN 'No Parking'
WHEN 'double_parking' THEN 'Double Parking'
WHEN 'no_standing'    THEN 'No Standing'
WHEN 'fire_hydrant'   THEN 'Fire Hydrant'
END`.replaceAll( "\n", " " );

//the colNames are for internal use, and shouldn't be shown to customers
//this array maps domo cols to friendly names for export
const columns = [
 // { colName: "lane_uuid",                friendlyName: "Lane Id"       }, //big ugly uuid
    { colName: "curb_zone_name",           friendlyName: "Curb Zone"     },
    { colName: "camera_name",              friendlyName: "Camera Name"   },
    { colName: `${laneTypeConv}`,          friendlyName: "Lane Type"     },
    { colName: "vehicle_uuid",             friendlyName: "Vehicle Id"    },
    { colName: "vehicle_type",             friendlyName: "Vehicle Class" },
    { colName: "session_start_time_local", friendlyName: "Start Time"    },
    { colName: "session_end_time_local",   friendlyName: "End Time"      },
    { colName: "dwell_time_minutes",       friendlyName: "Duration"      },
    { colName: "vehicle_group",            friendlyName: "Vehicle Group" },
 // { colName: "camera_uuid",              friendlyName: "Camera Id"     }, //big ugly uuid
    { colName: "location",                 friendlyName: "Location"      },
    { colName: "start_image_url",          friendlyName: "Start Image"   },
    { colName: "end_image_url",            friendlyName: "End Image"     },
    { colName: "start_annotated_image_url",friendlyName: "Annotated Start Image" },
    { colName: "end_annotated_image_url",  friendlyName: "Annotated End Image"   },
];

export interface DomoQueryResp {
    datasource: string;
    columns:    string[];
    metadata:   Metadatum[];
    rows:       Array<Array<number | string>>;
    numRows:    number;
    numColumns: number;
    fromcache:  boolean;
}

export interface Metadatum {
    type:         Type;
    dataSourceId: string;
    maxLength:    number;
    minLength:    number;
    periodIndex:  number;
    aggregated:   boolean;
}

export enum Type {
    Datetime = "DATETIME",
    Double   = "DOUBLE",
    Long     = "LONG",
    String   = "STRING",
}

function getSelectClause() {
    const els = columns.map( col => `${col.colName} AS "${col.friendlyName}"` );
    return els.join( ', ' );
}

export class AnalyticsStayData {
     static getStayRecs( domoAuth: IDomoAuth, 
                         deplId: string,
                         curbZoneGrp: IZoneGroup,
                         startDate: Date, 
                         endDate: Date, 
                         daysOfWeek: DayOfWeek[], 
                         timeRange: number[] ) {
        //each domo dataset has been set up so that we can use the same where clause on each
        const where = this.whereClause( deplId, curbZoneGrp, startDate, endDate, daysOfWeek, timeRange );
        return DomoApi.datasetQuery( domoAuth,
            "ac08f248-4b77-47e8-83ec-fa87c6177732",
            `SELECT ${getSelectClause()} FROM table ${where}` );
    }

    static whereClause( deplId: string,
                        curbZoneGrp: IZoneGroup,
                        startDate: Date,
                        endDate: Date,
                        daysOfWeek: DayOfWeek[], 
                        timeRange: number[] ) {

        const filts: Array<string> = [];

        //deployement filter
        filts.push( `filter_deployment_id = '${deplId}'` );

        //curb zone filter
        if( curbZoneGrp && curbZoneGrp.type !== CurbZoneGroupType.All ) {
            const zoneList = curbZoneGrp.curbZones.map( cz => `'${cz}'` ).join( ', ' );
            filts.push( `filter_curb_zone IN (${zoneList})` )
        }

        //date filter
        let sd = DateTime.fromJSDate( startDate ).startOf( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        let ed = DateTime.fromJSDate( endDate   ).endOf  ( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        filts.push( `(filter_time > '${sd}' AND filter_time < '${ed}')` );

        //day of week filter
        const dayList = daysOfWeek.map( d => `'${d}'` ).join( ',' );
        filts.push( `filter_day_of_week IN (${dayList})` );

        //hour of day filter
        filts.push( `filter_hour_of_day >= ${timeRange[0]} AND filter_hour_of_day <= ${timeRange[1]}`);

        //generate where clause
        let whereCls = "";
        if( filts.length !== 0 ) {
            whereCls = "WHERE " + filts.join( " AND " ) + " ORDER BY filter_time ASC";
        }
        return whereCls;
    }
}
