import { Menu, MenuItem } from "@mui/material";
import { DotsVertical   } from "heroicons-react";
import { useState       } from "react";
import { IUser          } from "./Api/IUser";

export interface ContextMenuRecReportProps {
    user:   IUser;
    delete: () => void;
}

export function ContextMenuRecReport( props: ContextMenuRecReportProps ) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const open                    = Boolean( anchorEl );
    const handleClick             = ( event: any ) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => {
        setAnchorEl( null );
    };

    let adminMenu = <></>;
    if( props.user.isAdmin ) {
        adminMenu = <MenuItem onClick={ () => { props.delete(); handleClose() } }>Delete Report</MenuItem>;
    }

    return <>
        <DotsVertical
            style={{ cursor: "pointer" }}
            className="float-end"
            onClick={handleClick} />
        <Menu anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            {adminMenu}
        </Menu>
    </>;
}
