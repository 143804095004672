import React from 'react';
import { ITimeSpan } from '../../../Api/ITimeSpan';
import { DateTime } from 'luxon';
import {Badge, Stack} from "react-bootstrap";
import {Box} from "@mui/material";

interface TimeSpanViewProps {
    timeSpan: ITimeSpan;
}

const TimeSpanTextView: React.FC<TimeSpanViewProps> = ({ timeSpan }) => {
    const {
        startDate,
        endDate,
        daysOfWeek,
        daysOfMonth,
        months,
        timeOfDayStart,
        timeOfDayEnd,
    } = timeSpan;

    const DaysOfWeekBadges = () => {
        const badges = (daysOfWeek ?? []).map((day) => <Badge> {day} </Badge> )
        return <Stack direction={"horizontal"} gap={1}>
            {badges}
        </Stack>
    }

    const formatTime = (time: number): string => {
        if (time === 0) {
            return 'midnight';
        } else if (time >= 12) {
            return `${time % 12}pm`;
        } else {
            return `${time}am`;
        }
    };

    const displayTimeOfDayStart = timeOfDayStart !== undefined ? formatTime(timeOfDayStart) : 'Not specified';
    const displayTimeOfDayEnd = timeOfDayEnd !== undefined ? formatTime(timeOfDayEnd) : 'Not specified';

    return (
        <Box m={"1px"}>
            <DaysOfWeekBadges />
            {/*<p>*/}
                <strong>From:</strong> {displayTimeOfDayStart} - {displayTimeOfDayEnd}
            {/*</p>*/}
        </Box>
    );
};

export default TimeSpanTextView;
