import { DateTime } from "luxon";
import { IViolationRaw } from "./Api/IViolation";

export enum ViolationType {
    All           = "all", //special, for UI only
    Bike          = "bike_lane",
    NoParking     = "no_parking",
    DoubleParking = "double_parking",
    NoStanding    = "no_standing",
    FireHydrant   = "fire_hydrant"
}

export function vioDisp( violationType: ViolationType ) {
    switch( violationType ) {
        case ViolationType.All:           return "All";  //this one is special
        case ViolationType.Bike:          return "Parked in Bike Lane";
        case ViolationType.NoParking:     return "Parked in No Parking";
        case ViolationType.DoubleParking: return "Double Parked";
        case ViolationType.NoStanding:    return "Parked in No Standing";
        case ViolationType.FireHydrant:   return "Blocking Fire Hydrant";
        default:
            return violationType;
    }
}

export function processViolation( rec: IViolationRaw ): IViolation {
    const pnt    = JSON.parse( rec.location );
    const latLng = new google.maps.LatLng( pnt[1], pnt[0] )
    let res: IViolation = {
        violation:   rec.violation as ViolationType,
        location:    latLng,
        startImg:    rec.start_image_url,
        startTime:   DateTime.fromISO( rec.session_start_time_local ),
        endTime:     DateTime.fromISO( rec.session_end_time_local   ),
        duration:    parseInt( rec.dwell_time_minutes               ),
        vehicleId:   parseInt( rec.vehicle_uuid ),
        bbox:        rec.vehicle_bbox,
        groundPoly:  rec.vehicle_ground_plane
    };
    return res;
}


export function vioColor( violationType: ViolationType, sel: boolean ) {
    const colStr = vioColorOnly( violationType );
    const selStr = sel ? "-sel" : "";
    return `${colStr}${selStr}`;
}

export function vioColorOnly( violationType: ViolationType ) {
    switch( violationType ) {
        case ViolationType.Bike:          return "blue";
        case ViolationType.NoParking:     return "orange";
        case ViolationType.DoubleParking: return "teal";
        case ViolationType.NoStanding:    return "purple";
        case ViolationType.FireHydrant:   return "red";
        default:
            return "grey";
    }
}

export interface IViolation {
    violation:  ViolationType;
    location:   google.maps.LatLng;
    startImg:   string;
    startTime:  DateTime;
    endTime:    DateTime;
    duration:   number;
    vehicleId:  number;
    bbox:       Array<Array<number>>;
    groundPoly: Array<Array<number>>;
}