import { MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBTabs } from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { DemandTab } from "./Activity";
import { IZone, IDepl } from "./Api/IDeployment";
import { IUser } from "./Api/IUser";
import { VadeFirebase } from "./Api/VadeFirebase";
import { IZoneGroup, CurbZoneGroupType } from "./IZoneGroup";
import { ComplianceTab } from "./ComplianceTab";
import { SupplyTab } from "./SupplyTab";
import { doc, setDoc } from "firebase/firestore";
import { CreateUserDialog } from "./Controls/CreateUserDialog";
import { CreateDeplDialog } from "./Controls/CreateDeplDialog";
import { TabImage } from "./Controls/TabImage";
import { AppTabsUserMenu } from "./AppTabsUserMenu";
import { Setter } from "./Util/MeasureTypes";

//not sure about the best modular design for this
const vadeFirebase: VadeFirebase = new VadeFirebase();

interface AppTabsProps {
    depl:     IDepl;
    setDepl:  Setter<IDepl | undefined>;
    zones:    IZone[];
    setZones: Setter<IZone[]>; 
    user:     IUser;
    logout:   () => void;
}

export function AppTabs( props: AppTabsProps )  {
    const [activeTab,      setActiveTab     ] = useState( "supply" );
    const [customGrps,     setCustomGrps    ] = useState<IZoneGroup[]>( [] );
    const [showCreateUser, setShowCreateUser] = useState<boolean>( false );
    const [showCreateDepl, setShowCreateDepl] = useState<boolean>( false );

    function loadCustomGroups() {
        vadeFirebase.getDepl( props.depl.uuid ).then( resp => {
            const deplData = resp.data();
            if( !deplData?.customCurbZoneGrps ) {
                return;
            }
            const grps: any[] = deplData.customCurbZoneGrps;
            setCustomGrps( grps );
        } );
    }

    function saveCustomGroups( grpsToSave: IZoneGroup[] ): void {
        vadeFirebase.saveCustomCurbZoneGrps( props.depl.uuid, grpsToSave );
    }

    useEffect( () => {
        loadCustomGroups();
    }, [] );

    function addCustomGrp( customGrp: IZoneGroup ) {
        let updatedGrps = [...customGrps, customGrp];
        setCustomGrps   ( updatedGrps );
        saveCustomGroups( updatedGrps );
    }

    function updateCustomGrp( customGrp: IZoneGroup ) {
        const updatedGrps = customGrps.map( g => {
            if( g.name === customGrp.name ) {
                return { ...g, ...customGrp };
            }
            return g;
        } );
        setCustomGrps   ( updatedGrps );
        saveCustomGroups( updatedGrps );
    }

    function removeCustomGrp( customGrp: IZoneGroup ) {
        const updatedGrps = customGrps.filter( g => g.name != customGrp.name );
        setCustomGrps   ( updatedGrps );
        saveCustomGroups( updatedGrps );
    }

    const handleTabClick = ( value: string ) => {
        if( value === activeTab ) { return; }
        setActiveTab( value );
    };

    function createUser( email:     string,
                         password:  string,
                         deplId:    string,
                         isAdmin:   boolean ): void {
        const docRef = setDoc( doc( vadeFirebase.db, "measureUsers", email ), {
            email:        email,
            deploymentId: deplId,
            isAdmin:      isAdmin
        } );
        docRef.then( resp => {
            console.log( resp );
            alert( "user firestore record created!" );
        } );
    }

    function createDepl( deplId: string, startDate: string, endDate: string ): void {
        const docRef = setDoc( doc( vadeFirebase.db, "measureDeployments", deplId ), {
            defaultFilterStartDate: startDate,
            defaultFilterEndDate: endDate
        }, { merge: true } ); //make sure to not blow away existing stuff
        docRef.then( resp => {
            console.log( resp );
            alert( "depl firestore record created!" );
        } );
    }

    const userInfo = <div className="user-bar">
        <div className="p-2">
            {props.user.email}
        </div>
        &nbsp;
        <AppTabsUserMenu
            user={ props.user }
            createUser={ () => setShowCreateUser( true ) }
            createDepl={ () => setShowCreateDepl( true ) }
            logout={     () => props.logout() } />      
        <CreateUserDialog show={showCreateUser}
                          setShow={setShowCreateUser}
                          deploymentId={props.user.deploymentId}
                          commit={createUser} />
        <CreateDeplDialog show={showCreateDepl}
                          setShow={setShowCreateDepl}
                          commit={createDepl} />
    </div>;

    //we add the "entire deployment" option here
    let newVal: IZoneGroup[] = [{
        name:      "Entire Deployment",
        type:      CurbZoneGroupType.All,
        curbZones: []
    }, ...customGrps];
    
    const displayGrps = newVal;
    return <div className="tabs">
        <div className="tabs-and-user-bar">
            <MDBTabs className="mb-0 w-100">
                <MDBTabsItem style={{ cursor: "default" }}>
                    <MDBTabsLink className="disabled">                        
                        <img src="/img/vade-v.svg" width="20" />
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleTabClick( "supply" )} active={activeTab === "supply"}>
                        <TabImage src="/img/icon-zones.svg" label="Supply" />
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleTabClick( "demand" )} active={activeTab === "demand"}>
                        <TabImage src="/img/icon-analytics.svg" label="Demand" />
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem >
                    <MDBTabsLink onClick={() => handleTabClick( "compliance" )} active={activeTab === "compliance"}>
                        <TabImage src="/img/icon-safety.svg" label="Compliance" />
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            {userInfo}
        </div>
        <MDBTabsContent className="fullheight cory-tab">
            <MDBTabsPane className="fullheight cory-tab" show={activeTab === "supply"}>
                <SupplyTab depl={props.depl}
                           setDepl={props.setDepl}
                           zones={props.zones}
                           setZones={props.setZones}
                           user={props.user}
                           customGroups={customGrps}
                           addZoneGroup={addCustomGrp} 
                           removeZoneGroup={removeCustomGrp} />
            </MDBTabsPane>
            <MDBTabsPane className="fullheight cory-tab" show={activeTab === "demand"}>
                <DemandTab
                    user={props.user}
                    depl={props.depl}
                    curbZoneGroups={displayGrps} 
                    updateCurbZoneGroup={updateCustomGrp} />
            </MDBTabsPane>
            <MDBTabsPane className="fullheight cory-tab" show={activeTab === "compliance"}>
                <ComplianceTab 
                    depl={props.depl}
                    user={props.user} />
            </MDBTabsPane>
        </MDBTabsContent>
    </div>;
}