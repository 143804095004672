import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";

import {AddButton, Circle, TrashButton} from "../../../../Util/UIHelpers";
import {Options} from "../../../../Util/MeasureTypes";
import {defaultTimeSpan, ITimeSpan} from "../../../../Api/ITimeSpan";
import ToggleDays from "./ToggleDays";
import {TimeRangeSlider} from "./TimeRangeSlider";
import {getColorForNumber} from "../TimeSpanHelpers";


export interface TimeSpanEditTablebProps{
    timeSpans: ITimeSpan[];
    timeSpansDidChange?: (newRules: ITimeSpan[], idx: number) => void
}

const TimeSpanEditTable:  React.FC<TimeSpanEditTablebProps>  = ({timeSpans , timeSpansDidChange}) => {
    const updateTimeSpan = (options: Options<ITimeSpan>, idx: number) => {
        let newSpans = [...timeSpans]
        const currRule = newSpans[idx]
        newSpans[idx] = {...currRule, ...options}
        timeSpansDidChange && timeSpansDidChange(newSpans, idx)
    }

    const onAddTimeSpan = () => {
        let newRule = defaultTimeSpan()
        let newRules = [...timeSpans, newRule]
        timeSpansDidChange && timeSpansDidChange(newRules, timeSpans.length)
    }

    const onDeleteTimespan = (idx: number) => {
        let oldRules = [...timeSpans]
        oldRules.splice(idx, 1);
        timeSpansDidChange && timeSpansDidChange(oldRules, idx)
    }
    const handleSlider = (val: any, idx: number) => {
        var endVal = val[1]
        if (endVal === 24){
            endVal = 24
        }
        updateTimeSpan( { timeOfDayStart: val[0], timeOfDayEnd: endVal } , idx)
    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ minWidth: 200, maxWidth: 250, textAlign: "center" }}>Days Of Week</TableCell>
                        <TableCell style={{ minWidth: 200, maxWidth: 250, textAlign: "center" }}>Hours Of Day</TableCell>
                        <TableCell>
                            <AddButton onClick={onAddTimeSpan}/>
                        </TableCell>
                    </TableRow>
                    {/*</TableHead>*/}
                    <TableBody>
                        {timeSpans.map((span, idx) => (
                            <TableRow key={`rule_cell_${idx}`}>

                                <TableCell style={{textAlign: "center"}}>
                                    <Circle color={getColorForNumber(idx)} size={10} />
                                </TableCell>

                                <TableCell style={{textAlign: "center"}}>
                                    <ToggleDays initalValue={span.daysOfWeek} onSelectionChange={(days) => {updateTimeSpan({daysOfWeek: days}, idx) }} />
                                </TableCell>

                                <TableCell style={{textAlign: "center"}}>
                                    <TimeRangeSlider
                                        timeRange={[span.timeOfDayStart ?? 0, span.timeOfDayEnd ?? 24]}
                                        setTimeRange={(x) => handleSlider(x, idx)} />
                                </TableCell>

                                <TableCell>
                                    <TrashButton onClick={() => onDeleteTimespan(idx)} />
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TimeSpanEditTable