import {TabPanelProps} from "../../ZoneDialogUtil";
import {ICurbZone, IDepl, IDeployment, IZone} from "../../../Api/IDeployment";
import {GlobalContext} from "../../../App";
import React, {useContext, useEffect, useState} from "react";
import {IPolicy} from "../../../Api/IPolicy";
import {
    Button,
    Checkbox,
    DialogActions, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import timeSpanEditOld from "../TimespanControls/TimeRangeEditOld/TimeSpanEditOld";
import TimeSpanTextView from "../TimespanControls/TimeSpanTextView";
import {MDBBtn} from "mdb-react-ui-kit";
import {Api} from "../../../Api/Api";
import {Confirm} from "../../Confirm";
import policyEditor from "../PolicyEditor";
import {EditButton, TrashButton} from "../../../Util/UIHelpers";
import {PolicyEditorDialog} from "../PolicyEditorDialog";
import {IRule} from "../../../Api/IRule";
import {Badge} from "react-bootstrap";
import {UserClass} from "../../../Api/IUserClass";
import {rateToTextPrimary} from "../../../Api/IRate";


interface SelectPolicyTabProps extends TabPanelProps{
    zone: ICurbZone
    policiesChanged?: (policies: IPolicy[] ) => void
    cancel: () => void
    commit: () => void
}

interface DelPromptData{
    policy: IPolicy;
    affectedCurbzones: any[]
}

function SelectPolicyTab(props: SelectPolicyTabProps) {
    const { value, index, zone, policiesChanged, cancel, commit, ...other } = props;
    const depl = useContext(GlobalContext)!.depl;
    const policies = depl.policies.filter((pol) => !pol.archived)

    const [selectedPolicyIds, setSelectedPolicyIds] = useState<String[]>(zone.curb_policy_ids);
    const [showDelPrompt, setShowDelPrompt] = useState(false)
    const [delPromptData, setDelPromptData] = useState<DelPromptData | undefined>()

    const [showPolicyEdit, setShowPolicyEdit] = useState(false)
    const [editingPolicy, setEditingPolicy] = useState<IPolicy | undefined>()


    useEffect(() => {
        const selectedPolicies: IPolicy[] = policies.filter((pol) => selectedPolicyIds.includes(pol.curbPolicyId))
        policiesChanged && policiesChanged(selectedPolicies)
    }, [selectedPolicyIds])

    const handleRowSelect = (policyId: String) => {
        if (selectedPolicyIds.includes(policyId)) {
            setSelectedPolicyIds(selectedPolicyIds.filter((rowId) => rowId !== policyId))
        } else {
            setSelectedPolicyIds([...selectedPolicyIds, policyId]);
        }
    };

    async function handleForcedDelete(policy: IPolicy){
        const resp = await Api.archivePolicy(policy.curbPolicyId, true)
        if (resp.status === 200){
            depl.policies = depl.policies.filter((pol) => pol.curbPolicyId !== policy.curbPolicyId)
            const deplResp = await Api.getDeployment(depl.uuid)
            if (deplResp.ok){
                const deplJson = await deplResp.json()
                const deplData: IDeployment = { ...deplJson.deployment };
                depl.curbZones = deplData.curb_zones
            }
        }else{
            console.error(`Failed to force archive policy. Please check network`)
        }
        setShowDelPrompt(false)
        setDelPromptData(undefined)
    }

    async function handleDelete(policy: IPolicy){
        const resp = await Api.archivePolicy(policy.curbPolicyId)
        if (resp.status === 200){
            depl.policies = depl.policies.filter((pol) => pol.curbPolicyId !== policy.curbPolicyId)
            setShowDelPrompt(false)
            setDelPromptData(undefined)
            return
        }
        if (resp.status === 403){
            const respJson = await resp.json()
            const errJson = JSON.parse(respJson.error)
            const affectedZones = errJson.curbzones as ICurbZone[]
            let delPromptData: DelPromptData = {
                policy: policy,
                affectedCurbzones: affectedZones
            }
            setDelPromptData(delPromptData)
            setShowDelPrompt(true)
        }else{
            const respJson = await resp.json()
            setShowDelPrompt(false)
            console.error(`Failed to archive policy: ${JSON.parse(respJson)}`)
            alert("Failed to delete policy")
        }
    }

    const RedTextZone = (props: {cz: ICurbZone}) => {
        return <div style={{margin: "5px"}} className="d-inline-block alert alert-danger py-1 p-2 rounded-2">
            {props.cz.name}
        </div>
    }

    const handleEdit = (policy: IPolicy) => {
        setShowPolicyEdit(true)
        setEditingPolicy(policy)
    }

    const handleEditCommited = (policy: IPolicy) => {
        setShowPolicyEdit(false)
        setEditingPolicy(undefined)
        const polIdx = depl.policies.findIndex((pol) => pol.curbPolicyId === policy.curbPolicyId)
        if (!polIdx) return
        depl.policies[polIdx] = policy
    }

    const PoicyIDToolTip = (props: {policy: IPolicy}) => {
        return <div style={{ whiteSpace: 'pre-line' }}>
            Curb Policy ID
            <br/>
            {props.policy.curbPolicyId}
        </div>
    }

    const RuleBadge = (props: {rule: IRule, userClass: UserClass}) => {
        const {rule, userClass} = props
        const isAllowRule = rule.activity.includes("no")
        const badgeColor = isAllowRule ? "danger" : "success"
        const rates = (rule.rate ?? []).map((rate) => rateToTextPrimary(rate))
        const toolTipText = <div style={{ whiteSpace: 'pre-line' }}>
            {rule.activity}
            <br/>
            {isAllowRule ? "" : rates.join(", ")}
        </div>
        return <Tooltip arrow  disableInteractive title={toolTipText}>
            <Badge bg={badgeColor} style={{margin: "1px"}}>
                {userClass}
            </Badge>
        </Tooltip>
    }

    const RulesBadges = (props: {rules: IRule[]}) =>  {
        const rules = props.rules
        let allBadges = []
        for (const rule of rules){
            const badges = rule.user_classes.map((user) => <RuleBadge rule={rule} userClass={user} />)
            allBadges.push(...badges)
        }
        return <div >
            {allBadges}
        </div>
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                // This is where the meat sits
                <div>
                    <Confirm
                        title={`This Policy Is Used By ${delPromptData?.affectedCurbzones?.length} Curb Zones`}
                        label={
                            `Are you sure you want to delete "${delPromptData?.policy?.name}"?
                            This will remove the policy from the following curbzones:`
                        }
                        commitLabel="Delete"
                        show={ showDelPrompt }
                        setShow={ setShowDelPrompt }
                        commit={ () => delPromptData && handleForcedDelete( delPromptData.policy! ) }
                    >
                        {delPromptData?.affectedCurbzones?.map((cz) => <RedTextZone cz={cz} /> )}
                    </Confirm>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Select</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell style={{ minWidth: 20, maxWidth: 100 }}>Curb Users</TableCell>
                                    <TableCell>Timespans</TableCell>
                                    <TableCell>Priority</TableCell>
                                    <TableCell style={{ minWidth: 20, maxWidth: 20 }}> </TableCell>
                                    <TableCell style={{ minWidth: 20, maxWidth: 20 }}> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {policies.map((policy) => {
                                    return <TableRow key={policy.curbPolicyId + "_policy_row"}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedPolicyIds.includes(policy.curbPolicyId)}
                                                onChange={() => handleRowSelect(policy.curbPolicyId)}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Tooltip title={<PoicyIDToolTip policy={policy} /> } arrow>
                                               <strong> {policy.name} </strong>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell>
                                            <RulesBadges rules={policy.rules} />
                                        </TableCell>

                                        <TableCell>
                                            <Stack>
                                                {policy.timeSpans.map((tSpan, idx) => (
                                                    <TimeSpanTextView timeSpan={tSpan} />))}
                                            </Stack>
                                        </TableCell>

                                        <TableCell>{policy.priority}</TableCell>
                                        <TableCell> <EditButton onClick={() =>  handleEdit(policy)}   /></TableCell>
                                        <TableCell> <TrashButton onClick={() => handleDelete(policy)} /> </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DialogActions>
                        <MDBBtn color="light" onClick={cancel}>Cancel</MDBBtn>
                        <MDBBtn color="primary" onClick={commit}>Submit</MDBBtn>
                    </DialogActions>

                    {editingPolicy &&
                        <PolicyEditorDialog
                        show={showPolicyEdit}
                        setShow={setShowPolicyEdit}
                        didCommit={handleEditCommited}
                        policy={editingPolicy}
                        />
                    }
                </div>
            )}
        </div>
    );
}

export default SelectPolicyTab
