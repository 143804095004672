export class TimeRangeSliderUtil {
    static dispHour( value: number ): string {
        if( value == 0  ) { return "12 AM"    }
        if( value == 1  ) { return "1 AM"     }
        if( value == 2  ) { return "2 AM"     }
        if( value == 3  ) { return "3 AM"     }
        if( value == 4  ) { return "4 AM"     }
        if( value == 5  ) { return "5 AM"     }
        if( value == 6  ) { return "6 AM"     }
        if( value == 7  ) { return "7 AM"     }
        if( value == 8  ) { return "8 AM"     }
        if( value == 9  ) { return "9 AM"     }
        if( value == 10 ) { return "10 AM"    }
        if( value == 11 ) { return "11 AM"    }
        if( value == 12 ) { return "12 PM"    }
        if( value == 13 ) { return "1 PM"     }
        if( value == 14 ) { return "2 PM"     }
        if( value == 15 ) { return "3 PM"     }
        if( value == 16 ) { return "4 PM"     }
        if( value == 17 ) { return "5 PM"     }
        if( value == 18 ) { return "6 PM"     }
        if( value == 19 ) { return "7 PM"     }
        if( value == 20 ) { return "8 PM"     }
        if( value == 21 ) { return "9 PM"     }
        if( value == 22 ) { return "10 PM"    }
        if( value == 23 ) { return "11 PM"    }
        if( value == 24 ) { return "11:59 PM" }
        return "Err!";
    }
}
