
export class ApiUrl {
    static detectServ( url: string ): string {
        // if (url.includes("localhost")){
        //     return "http://localhost:3011"
        // }
        return 'https://us-central1-vade-prod.cloudfunctions.net/vade_crud_api';
    }
    static detectRealtimeServ( url: string ): string {
        return 'https://us-central1-vade-prod.cloudfunctions.net/realtime_api';
    }
    static detectImageServ( url: string ): string {
        return 'https://storage.googleapis.com/trace_images_prod';
    }
    static readonly serv = this.detectServ( window.location.href );
    static readonly realtimeServ = this.detectRealtimeServ( window.location.href ); 
    static realtimeData() { //POST
        return `${this.realtimeServ}/occupancy/by_deployment`;
    }
    static deploymentDetails( deplId: string ) { //GET
        return `${this.serv}/deployment/get_deployment_by_uuid?uuid=${deplId}`;
    }
    static readonly imageServ = this.detectImageServ( window.location.href );

    static traceImgOriginal( traceId: string ) {
        return `${this.imageServ}/${traceId}_original.jpg`;
    }
    static latestTraceImgWithVision( camId: string ) {        
        return `https://us-central1-vade-prod.cloudfunctions.net/realtime_api/images/get_occupancy_image?api_key=realtime-0109820938029832&camera_uuid=${camId}&include_vehicles=true`
    }
    static traceImgThumbnail( traceId: string ) {
        return `${this.imageServ}/${traceId}_thumbnail.jpg`;
    }

    static curbZoneCreate() { //POST
        return `${this.serv}/curb_zone/create_curb_zone`;
    }
    static curbZoneUpdate() { //POST
        return `${this.serv}/curb_zone/update_curb_zone`;
    }

    static policyCreate() { //POST
        return `${this.serv}/policy/create_policy`;
    }

    static policyUpdate() {
        return `${this.serv}/policy/update_policy`;
    }
    static policyArchive() {
        return `${this.serv}/policy/archive_policy`;
    }
}