import React, { useState, ChangeEvent, useEffect } from 'react';
import {Box, Grid, TextField, Typography} from '@mui/material';

import { IPolicy } from '../../Api/IPolicy';
import { IRule } from '../../Api/IRule';
import { ITimeSpan } from '../../Api/ITimeSpan';
import RuleEditTable from './RuleControls/RuleEditTable';
import { Options } from '../../Util/MeasureTypes';
import {NumFld, StrFld} from "../ZoneDialogUtil";
import TimeSpanViewCalendar from "./TimespanControls/TimeSpanViewCalendar/TimeSpanViewCalendarFree";
import TimeSpanEditTable from "./TimespanControls/TimeSpanEditTable/TimeSpanEditTable";

interface PolicyEditorProps {
    startingPolicy: IPolicy;
    onChange: (policy: IPolicy) => void;
}

const PolicyEditor: React.FC<PolicyEditorProps> = ({ startingPolicy, onChange }) => {
    const [policy, setPolicy] = useState<IPolicy>(startingPolicy);
    const set = (options: Options<IPolicy>) => {
        setPolicy((x) => ({ ...x, ...options }));
    };

    useEffect(() => {
        onChange(policy);
    }, [policy, onChange]);


    const handleRulesChange = (rules: IRule[], idx: number) => {
        set({rules: rules})
    };

    const handleTimeSpansChange = (timeSpans: ITimeSpan[], idx: number) => {
        // policy.timeSpans = timeSpans
        const newSpans = [...timeSpans]
        set({timeSpans: newSpans})
    };

    return (
        <div key={policy.curbPolicyId}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <StrFld lbl="Policy Name"  val={policy.name} set={ v => set( { name: v } ) } />
                </Grid>
                <Grid item xs={6}>
                    <NumFld lbl="Priority" val={policy.priority} set={ v => set( { priority: v }) } />
                </Grid>
            </Grid>
            <br/>
            <Typography variant="body1" fontWeight="bold">&nbsp;&nbsp;&nbsp;Rules</Typography>
            <RuleEditTable rules={policy.rules} rulesDidChange={handleRulesChange} />
            <br/>
            <Typography variant="body1" fontWeight="bold">&nbsp;&nbsp;&nbsp;Time Spans</Typography>
            <Box sx={{ border: 1 , borderColor: 'lightgrey', borderRadius: '16px'}}>
                <TimeSpanEditTable
                    timeSpans={policy.timeSpans}
                    timeSpansDidChange={handleTimeSpansChange} />
                <br/>
                <TimeSpanViewCalendar policy={policy} timeSpans={policy.timeSpans} onTimeSpanHover={ () => {} } />
            </Box>
        </div>
    );
};

export default PolicyEditor;
