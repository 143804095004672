import { InfoWindow, Polyline, useGoogleMap } from "@react-google-maps/api";
import { MDBSwitch } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { LocationMarker } from "heroicons-react";
import { IUser } from "../Api/IUser";
import { getPathCenter, getPathFromCurbZone } from "../Util/MapUtil";
import { getMeasureMapOptions } from "./MeasureMapOptions";
import { ICurbZone, IZone, IDepl } from "../Api/IDeployment";
import { LatLng } from "../Api/LatLng";
import { IZoneGroup } from "../IZoneGroup";
import { MeasureMap } from "./MeasureMap";

export interface ZoneEditorProps {
    user:                IUser;
    depl:                IDepl;
    curbZoneGroup:       IZoneGroup;
    updateCurbZoneGroup: ( curbZoneGroup: IZoneGroup ) => void;
    setShowZoneEditor:   React.Dispatch<React.SetStateAction<boolean>>
}

//you can't edit the "Entire Deployment" option
export function ZoneEditor( props: ZoneEditorProps ) {
    const [curbZones, setCurbZones] = useState<IZone[]>( loadCurbZones( props.curbZoneGroup, props.depl.curbZones ) );
    const [zoom,      setZoom     ] = useState<number>( 13 );
    const [gMap,      setGMap     ] = useState<google.maps.Map>();
    const [mapPnt,    setMapPnt   ] = useState<LatLng>();
    const [showInfo,  setShowInfo ] = useState<IZone>();
    const [hover,     setHover    ] = useState<IZone>();

    function loadCurbZones( grp: IZoneGroup, allCurbZones: ICurbZone[] ) {
        return allCurbZones.map( cz => {
            const exists = grp.curbZones.findIndex( gcz => gcz === cz.curb_zone_id ) !== -1;
            return { ...cz, enabled: exists };
        } );
    }

    const depl = props.depl;
    if( !depl ) {
        return <></>;
    }

    //zoom line thickness adjustment
    let thickMap = new Map<number, number>();
    const thin = 1;
    thickMap.set( 12, thin );
    thickMap.set( 13, thin );
    thickMap.set( 14, thin );
    thickMap.set( 15, thin );
    thickMap.set( 16, thin );
    thickMap.set( 17, thin );
    thickMap.set( 18, thin );
    thickMap.set( 19, 7 );
    thickMap.set( 20, 8 );
    thickMap.set( 21, 9 );

    let curbZoneLines: any = <></>;
    if( zoom && gMap && depl?.lanes?.length !== 0 ) {
        const thickness = thickMap.get( zoom! );
        //get rid of ones that aren"t selected
        curbZoneLines = curbZones.map( ( cz, i ) => {
            const path = getPathFromCurbZone( cz.gis_line );
            const color = cz.enabled ? "green" : "gray";
            return <Polyline
                key={`pline-front-${i}`}
                path={path}
                options={{ strokeWeight: 10, 
                           strokeColor:  color,
                           zIndex: 1
                        }}
                onMouseOver={ e => setHover( cz )        }
                onMouseOut={  e => setHover( undefined ) }
                onClick={     e => setShowInfo( cz )     }
                onDblClick={  e => handleChange( cz )    }
                draggable={false} />;
        } );
    }

    let infoWind = <></>;
    if( showInfo ) {
        const pathCenter = getPathCenter( getPathFromCurbZone( showInfo.gis_line ) );
        infoWind = <InfoWindow position={pathCenter}
                               onCloseClick={ () => setShowInfo( undefined ) }>
            <div style={{ fontSize: "0.9em" }}>
                {showInfo.name} <br />
            </div>
        </InfoWindow>;
    }

    function handleChange( cz: ICurbZone ) {
        const updatedCurbZones = curbZones.map( curbZone => {
            if( curbZone.curb_zone_id !== cz.curb_zone_id ) {
                return curbZone;
            }
            return { ...curbZone, enabled: !curbZone.enabled };
        } );
        setCurbZones( updatedCurbZones );
    }

    function selectAllCurbZones() {
        const set = !areAllZonesSelected();
        setCurbZones( curbZones.map( cz => {
            return { ...cz, enabled: set };
        } ) );
    }

    function areAllZonesSelected() {
        return curbZones.every( cz => cz.enabled );
    }

    function changeZoom() {
        const zm = gMap?.getZoom();
        if( !zm ) {
            return;
        }
        setZoom( zm );
    }
    
    function PanComp() {
        const map: google.maps.Map = useGoogleMap()!;
        useEffect( () => {
            if( !map    ) { return; }
            if( !mapPnt ) { return; }
            map.panTo( { lat: mapPnt.lat, lng: mapPnt.lng } );
            setMapPnt( undefined );
        }, [mapPnt] );
        return <></>;
    }

    function panToCurbZone( cz: ICurbZone ): void {
        let path: google.maps.LatLngLiteral[] = cz.gis_line.coordinates.map( p => {
            return { lat: p[1], lng: p[0] }
        } );
        setMapPnt( getPathCenter( path ) );
    }
    
    const containerStyle = { width: "100%", height: "100%" };
    return <div className="tab-supply">
        <div style={{ overflowY: "auto" }}>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <td colSpan={2} className="fs-3 text-center">
                            <span>Editing Curb Zone Group "{props.curbZoneGroup.name}"</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img src="/img/vade-circle-v.svg" width={30}  alt="vade logo" /> <strong>Select All Curb Zones</strong>
                        </td>
                        <td>
                            <MDBSwitch checked={areAllZonesSelected()} onChange={e => selectAllCurbZones()} />
                        </td>
                    </tr>
                    { props.depl.curbZones.map( deplCurbZone => {
                        const highlight = deplCurbZone.curb_zone_id === hover?.curb_zone_id ? "table-active" : "";
                        const curbZone  = curbZones.find( cz => deplCurbZone.curb_zone_id === cz.curb_zone_id );
                        let enabled = false;
                        if( curbZone ) {
                            enabled = curbZone.enabled;
                        }
                        return <tr key={deplCurbZone.curb_zone_id} className={highlight}>
                            <td className="w-100 d-flex align-items-center text-capitalize"
                                style={{ fontSize: ".9em", cursor: "pointer" }}
                                onClick={ e => panToCurbZone( deplCurbZone ) }>
                                <LocationMarker width={20} color={highlight} /> &nbsp; {deplCurbZone.name}
                            </td>
                            <td>
                                <MDBSwitch checked={ enabled } onChange={ e => handleChange( deplCurbZone )} />
                            </td>
                        </tr>
                    } ) }
                    <tr>
                        <td colSpan={2} className="text-center">
                            <button className="btn btn-primary" onClick={ () => {
                                props.setShowZoneEditor( false );
                                const res = { ...props.curbZoneGroup };
                                res.curbZones = curbZones.filter( cz => cz.enabled ).map( cz => cz.curb_zone_id );
                                props.updateCurbZoneGroup( res );
                            } }>Return to Analytics</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <MeasureMap
            options={getMeasureMapOptions()}
            center={props.depl.extras.loc}
            mapContainerStyle={containerStyle}
            zoom={13}
            onLoad={m => setGMap( m )}
            onZoomChanged={() => changeZoom()}>
            {curbZoneLines}
            {infoWind}
            <PanComp />
        </MeasureMap>
    </div>;
}
