import { DomoApiUrl } from "./DomoApiUrl";

export interface IDomoAuth {
    access_token: string;
    token_type:   string;
    expires_in:   number;
    scope:        string;
    customer:     string;
    env:          string;
    userId:       number;
    role:         string;
    domain:       string;
    jti:          string;
}

export class DomoApi {

    //client
    static domoClientId = "d34c65c4-5dfd-4a69-91fe-afa6b96e4158"

    //secret
    static domoSecret = "63a6aaed1a1e02f3bc6796169cae732b9e50f51b3d6c18dadc545acad1c110d3"

    static getAuthToken() {
        return fetch( DomoApiUrl.getOAuthToken(), {
            method : 'GET',
            headers: { 'Authorization': 'Basic ' + window.btoa( this.domoClientId + ":" + this.domoSecret ) }
        } );
    }

    static getAllViolations( auth: IDomoAuth, query: string ) {
        const ds = "79ecb75e-2b21-4873-863f-7bda68243298";
        return this.datasetQuery( auth, ds, query );
    }

    static datasetQuery( auth: IDomoAuth, dataset: string, query: string ) {
        let payload = { sql: query };
        return fetch( DomoApiUrl.dataset( dataset ), {
            method : 'POST',
            headers: { 'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': 'bearer ' + auth.access_token },
            body: JSON.stringify( payload )
        } );
    }
}