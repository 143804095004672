import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { useState } from "react";
import { Setter } from "../Util/MeasureTypes";

interface PromptProps {
    title:        string;
    label:        string;

    show:         boolean;
    setShow:      Setter<boolean>;

    initialValue?: string;
    commit:        ( name: string ) => void;
}

export function Prompt( props: PromptProps ) {
    const [name, setName] = useState( props.initialValue ? props.initialValue : "" );

    const close = () => {
        props.setShow( false );
        setName      ( ""    );
    }

    const commit = () => {
        if( name.trim() === "" ) { return; }
        close();
        props.commit( name! );
    }

    const cancel = () => close();

    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <TextField
                fullWidth
                margin   ="dense"
                label    ={props.label}
                type     ="text"
                variant  ="standard"
                value    ={name}
                inputRef ={input => input && input.focus()}
                onKeyDown={ (e) => {
                    if( e.key === "Enter" ) {
                        commit();
                        e.stopPropagation(); //didn't seem to make things better or worse :(
                     }
                }}
                onChange={ (e) => setName( e.target.value ) } />
        </DialogContent>
        <DialogActions>
            <Button onClick={cancel}>Cancel</Button>
            <Button onClick={commit}>Confirm</Button>
        </DialogActions>
    </Dialog>
}