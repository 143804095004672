import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { Setter } from "../Util/MeasureTypes";

interface AlertProps {
    title:        string;
    label:        string;
    confirmLabel: string;
    show:         boolean;
    setShow:      Setter<boolean>;
    commit:       () => void;
}

export function Alert( props: AlertProps ) {
    const close   = () => { props.setShow( false );  }
    const confirm = () => { close(); props.commit(); }    
    const cancel  = () => { close();                 }

    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.label}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <MDBBtn onClick={confirm}>{props.confirmLabel}</MDBBtn>
        </DialogActions>
    </Dialog>
}