import { Menu, MenuItem } from "@mui/material";
import { useState       } from "react";
import { getToolStr, SupplyTabMapTool as Tool } from "./SupplyTabMapTool";
import { Setter } from "./Util/MeasureTypes";

export interface SupplyTabMapToolMenuProps {
    tool   : Tool;
    setTool: Setter<Tool>;
}

export function SupplyTabMapToolMenu( { tool, setTool }: SupplyTabMapToolMenuProps ) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
    const open                    = Boolean( anchorEl );
    const handleClick             = ( event: any ) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => { setAnchorEl( null ); };
    return <div style={{ cursor: "pointer" }}>
        <div onClick={handleClick} className="btn-primary alert-primary rounded-5 px-3 py-2">
            {getToolStr( tool )}
        </div>
        <Menu anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
            <div>
                <MenuItem onClick={() => { setTool( Tool.View           ); handleClose(); }}>{getToolStr( Tool.View           )}</MenuItem>
                <MenuItem onClick={() => { setTool( Tool.DrawCurbZone   ); handleClose(); }}>{getToolStr( Tool.DrawCurbZone   )}</MenuItem>
                <MenuItem onClick={() => { setTool( Tool.EditCurbZone   ); handleClose(); }}>{getToolStr( Tool.EditCurbZone   )}</MenuItem>
                {/* <MenuItem onClick={() => { setTool( Tool.DrawParkingLot ); handleClose(); }}>{getToolStr( Tool.DrawParkingLot )}</MenuItem>
                <MenuItem onClick={() => { setTool( Tool.EditParkingLot ); handleClose(); }}>{getToolStr( Tool.EditParkingLot )}</MenuItem> */}
            </div>
        </Menu>
    </div>;
}

//OK, if View, everything works as usual...
