import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { collection, CollectionReference, doc, DocumentData, Firestore, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { IZoneGroup } from '../IZoneGroup';
import { IReportParams } from '../IReportParams';

export class VadeFirebase {

    // your web app's firebase configuration
    private readonly devConfig = {
        apiKey: "AIzaSyApIPSlBBYugsfi5bXpoxgtNtMhk8DTk8A",
        authDomain: "vade-dev-measure-378ad.firebaseapp.com",
        projectId: "vade-dev-measure",
        storageBucket: "vade-dev-measure.appspot.com",
        messagingSenderId: "755931165339",
        appId: "1:755931165339:web:a6079539e708431295cc3e"
      };

    //configure firebase
    private readonly config = {
        apiKey:            "AIzaSyBBQNKjmgTQ50QwkewEF7fc0SFA_54tx_I",
        authDomain:        "vade-prod.firebaseapp.com",
        databaseURL:       "https://vade-prod-default-rtdb.firebaseio.com",
        projectId:         "vade-prod",
        storageBucket:     "vade-prod.appspot.com",
        messagingSenderId: "463147940620",
        appId:             "1:463147940620:web:e07afb05edab8a4c37e729",
        measurementId:     "G-0GRVWNX9M7"
    };

    //cfg firebase ui
    public readonly uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/signedIn',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false, //avoid redirects after sign in
        },
    };

    readonly app:   firebase.app.App;
    readonly db:    Firestore;
    readonly users: CollectionReference<DocumentData>;
    
    isDev(): boolean {
        const url = window.location.href;
        // if( url.includes( "localhost" ) ) {
        //     return false;
        // }
        // if( url.includes( "dev" ) ) {
        //     return true;
        // }
        return false;
    }
    
    constructor() {
        if( this.isDev() ) {
            this.app = firebase.initializeApp( this.devConfig );
        } else {
            this.app = firebase.initializeApp( this.config    );
        }
        this.db    = getFirestore( this.app );
        this.users = collection( this.db, "measureUsers" );
    }

    async getDepl( deplId: string ) {
        const deplDocRef = doc( this.db, "measureDeployments", deplId );
        return await getDoc( deplDocRef );
    }

    async saveRecReports( deplId: string, reports: IReportParams[] ) {
        return await setDoc( doc( this.db, "measureDeployments", deplId ), { recommendedReports: reports }, { merge: true } );
    }

    async saveSavedReports( deplId: string, reports: IReportParams[] ) {
        return await setDoc( doc( this.db, "measureDeployments", deplId ), { savedReports: reports }, { merge: true } );
    }

    async saveCustomCurbZoneGrps( deplId: string, customGrps: IZoneGroup[] ) {
        return await setDoc( doc( this.db, "measureDeployments", deplId ), { customCurbZoneGrps: customGrps }, { merge: true } );
    }
}

