
export interface ILegend {
    threshold: number;
    color:     string;
    unit:      string; 
}

export interface ICurbZoneEntry {
    curbZoneName: string;
    curbZoneId:   string;
    value:        number;
    color:        string;
    units:        string;
}

export function legendize( els: ICurbZoneEntry[] ) {
    const min     = Math.min( ...els.map( el => el.value ) );
    const max     = Math.max( ...els.map( el => el.value ) );
    const spread  = Math.abs( min - max ); //get total range of values
    const incr    = spread / 5.0; //div into 5 partitions
    const buckets: Array<ILegend> = [];
    const colors  = [ "#42f542", "#b3f542", "#f2f542", "#f5a742", "#f54242" ];
    for( let i = 1; i <= 5; i++ ) {
        buckets.push( { unit: els[0].units, threshold: (min + (incr * i)), color: colors[i-1] } );
    }
    return buckets;
}

export function colorize( els: ICurbZoneEntry[] ): ICurbZoneEntry[] {
    const min     = Math.min( ...els.map( el => el.value ) );
    const max     = Math.max( ...els.map( el => el.value ) );
    const spread  = Math.abs( min - max ); //get total range of values
    const incr    = spread / 5.0; //div into 5 partitions
    const buckets: number[] = [];
    for( let i = 1; i <= 5; i++ ) {
        buckets.push( min + (incr * i) );
    }
    const colors  = [ "#42f542", "#b3f542", "#f2f542", "#f5a742", "#f54242" ];
    const res = els.map( el => {
        const bucket = buckets.findIndex( b => el.value <= b ); 
        if( bucket === -1 ) {
            console.error( "couldn't colorize" );
        }
        //console.log( "gave " + el.curbZoneId + " with v = " + el.value + " a colorization of " + colors[bucket] );
        el.color = colors[bucket];
        return el;
    } );
    return res;
}
