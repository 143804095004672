import { GoogleMap, GoogleMapProps, useGoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";

//probably should just let pages set h and w
const containerStyle = { width: '600px', height: '600px' };

export function MeasureMap( props: GoogleMapProps ) {
    let [center, setCenter] = useState<google.maps.LatLng | null>( null );
    let autoCompl: google.maps.places.Autocomplete | null = null;

    function onLoad( autoComplete: google.maps.places.Autocomplete ) {
        autoCompl = autoComplete;
    }

    function PanComp() {
        let map: google.maps.Map = useGoogleMap()!;
        useEffect( () => { //only when center changes!
            if( !map    ) { return; }
            if( !center ) { return; }
            map.panTo( { lat: center.lat(), lng: center.lng() } );
        }, [center] );
        return <></>;
    }

    let gMap = <GoogleMap 
        id="googleMap"
        mapContainerStyle={containerStyle}
        zoom={11}
        {...props}>
        {props.children}
    </GoogleMap>
    return gMap;
}