
export enum DayOfWeek {
    Sunday    = "sun",
    Monday    = "mon",
    Tuesday   = "tue",
    Wednesday = "wed",
    Thursday  = "thu",
    Friday    = "fri",
    Saturday  = "sat",
}

export const allDaysOfWeek: DayOfWeek[] = [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
]
