import { Polygon, Polyline } from "@react-google-maps/api";
import { DateTime } from "luxon";
import { ICam } from "./Api/ICam";
import { IZone, IDepl } from "./Api/IDeployment";
import { RealtimeEntry } from "./Api/RealtimeEntry";
import { getPathForLot, getPathFromCurbZone } from "./Util/MapUtil";
import { Setter } from "./Util/MeasureTypes";
import {Badge} from "react-bootstrap";

export const junkGeo = [[[-73.9690975864906, 40.75394188111892], [-73.96900642451338, 40.754081731525446], [-73.96909658649061, 40.75394288111892], [-73.96900542451338, 40.75408273152544], [-73.9690975864906, 40.75394188111892]]];

export function getStrokeWeight( zoom: number ) {
    //zoom line thickness adjustment
    let thickMap = new Map<number, number>();
    thickMap.set(  8,   9.0 ); //far away
    thickMap.set(  9,   9.0 );
    thickMap.set( 10,   9.0 );
    thickMap.set( 11,   9.0 );
    thickMap.set( 12,   9.0 );
    thickMap.set( 13,   9.0 );
    thickMap.set( 14,   1.1 );
    thickMap.set( 15,   1.5 );
    thickMap.set( 16,   2.1 );
    thickMap.set( 17,   3.6 );
    thickMap.set( 18,   5.6 );
    thickMap.set( 19,  10.3 );
    thickMap.set( 20,  10.3 );
    return thickMap.get( zoom )!;
}

export function lotsOpts( color: string, strokeWeight: number ) {
    return {
        fillColor:    color,
        fillOpacity:  0.5,
        strokeWeight: strokeWeight,
        strokeColor:  color
    };
}

export function curbLineOpts( color: string, strokeWeight: number, clickable: boolean = true ): google.maps.PolylineOptions {
    return {
        strokeWeight: strokeWeight,
        strokeColor:  color,
        zIndex:       1,
        clickable:    clickable
    };
}

export interface ZonesProps {
    zones:        IZone[];
    realtimeData: RealtimeEntry[];
    strokeWeight: number;
    showZone:     IZone | undefined;
    setShowZone:  Setter<IZone | undefined>;
    zoneHover:    ( zn: IZone | undefined ) => void;
    zoneClick:    ( e: google.maps.MapMouseEvent, zn: IZone ) => void;
    zoneDblClick: ( zn: IZone ) => void;
}

export function Zones( props: ZonesProps ) {
    const { realtimeData, zones, strokeWeight } = { ...props };
    let curbZoneMarkup = zones.map( ( zn, i ) => {
        let rt = realtimeData.find( el => el.curb_zone_id === zn.curb_zone_id );
        let color = zn.enabled ? "#4E66FF" : "#95a3ff";
        if( rt ) {
            color = zn.enabled ? rt.color : "#95a3ff";
        }
        if( zn.curb_zone_type == "parking_lot" ) {
            const path = getPathForLot( zn.geometry );
            return <Polygon
                key={`poly-${i}`}
                path={path}
                options={lotsOpts( color, strokeWeight ) }
                onMouseOver={ e => props.zoneHover( zn ) }
                onMouseOut={  e => props.zoneHover( undefined ) }
                onClick={     e => props.zoneClick( e, zn ) } 
                onDblClick={  e => props.zoneDblClick( zn ) }
                draggable={ false } />;
        } //otherwise it's a line        
        const path = getPathFromCurbZone( zn.gis_line );
        return <Polyline key={`pline-${i}`} path={path}
        options={curbLineOpts( color, strokeWeight )}
        onMouseOver={ e => props.zoneHover( zn )}
        onMouseOut={  e => props.zoneHover( undefined )}
        onClick={     e => props.zoneClick( e, zn ) }
        onDblClick={  e => props.zoneDblClick( zn )}
        draggable={false} />;
    } );
    return <>{curbZoneMarkup}</>;
}

export function ParkingLotInfo( props: { realtimeData?: RealtimeEntry } ) {
    const rt = props.realtimeData;
    if( !rt ) {
        return <></>;
    }
    if( rt.curb_zone_type !== 'parking_lot' ) {
        return <></>;
    }
    return <>Currently Monitoring {rt.total_covered_spaces} of {rt.total_spaces_in_lot} Spots</>;
}

function getOccupancyStr( realtime?: RealtimeEntry ) {
    if( !realtime ) {
        return <>Curb Zone Not Monitored</>;
    }
    return <>{realtime?.occupied_percentage.toFixed(0)}% Occupancy</>;
}

function zoneTypeDisp( typeStr: string ) {
    if( typeStr === "curb_parking"       ) { return "Curb Parking";       }
    if( typeStr === "parking_lot"        ) { return "Parking Lot";        }
    if( typeStr === "no_parking"         ) { return "No Parking";         }
    if( typeStr === "commercial_parking" ) { return "Commercial Parking"; }
    return "Err!";
}

export function ZoneWindow( props: { realtime?: RealtimeEntry,
                                         hover:     IZone,
                                         depl:      IDepl } ) {
    const { realtime, hover, depl } = { ...props };
    const deplTimezone     = depl.info.tz;
    let lastRealtimeUpdate = DateTime.fromISO( realtime?.last_updated_utc ?? "", { zone: "UTC" } );
    let lastUpdated        = "";
    if( lastRealtimeUpdate.isValid ) {
        lastRealtimeUpdate = lastRealtimeUpdate.setZone( deplTimezone );
        lastUpdated        = lastRealtimeUpdate.toRelative()!;
    }
    const zonePolicies = depl.policies.filter((pol) => hover.curb_policy_ids.includes(pol.curbPolicyId))
    const policyNames = zonePolicies.map((pol) => pol.name)
    const hasPolicies = zonePolicies.length > 0
    return <div className="rounded-5 px-3 p-2 info-window">
        <div>
            <div className="text-center"
                 style={{ fontSize:     "2.0em",
                          lineHeight:   "1.5em",
                          color: realtime?.color }}>
                {getOccupancyStr( realtime )}
            </div>
            <div>
                <h5>{hover.name}</h5>
                {zoneTypeDisp( hover.curb_zone_type )}
            </div>
            <div style={{ fontSize: "0.75em" }}>
                <div>Updated {lastUpdated}</div>
                <ParkingLotInfo realtimeData={realtime} />
            </div>
            {hasPolicies && (
            <div style={{marginTop: "10px"}}>
                <h5>Policies</h5>
                {policyNames.map((name) => <Badge style={{margin: "1px"}}> {name} </Badge>)}
            </div>)}
        </div>
    </div>;
}

export function getCamLocation( cam: ICam ) {
    let cs = cam?.current_installation?.location?.coordinates;
    let ret = { lat: 0.0, lng: 0.0 };
    if( !cs ) { return ret }
    ret = { lat: cs[1], lng: cs[0] };
    return ret;
}