import { ClickAwayListener }                  from "@mui/material";
import { MDBBtn }                             from "mdb-react-ui-kit";
import { CSSProperties, ReactNode, useState } from "react";

interface DropdownProps {
    label:    ReactNode;
    style?:   CSSProperties;
    children: ReactNode;
}

export function Dropdown( props: DropdownProps ) {
    const { children, style, ...rest } = props;
    const [show, setShow] = useState<boolean>( false );
    const payload = <div style={{
                                  zIndex: 1, 
                                  position: "absolute", 
                                  top: "100%",
                                  width: "100%" }}>
                        {children}
                    </div>;

    const content = show ? payload : <></>;
    return <ClickAwayListener onClickAway={ () => setShow( false ) }>
        <div style={{ position: "relative" }}>
            <MDBBtn style={{ ...style }}
                    onClick={ () => setShow( !show ) }>{props.label}</MDBBtn>
            {content}
        </div>
    </ClickAwayListener>
}