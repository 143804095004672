import { CurbZoneGroupType, IZoneGroup } from "./IZoneGroup";
import { getInitialDaysOfWeek, TimeGrouping } from "./DomoUtil";
import { ReportActivity } from "./ReportActivity";
import { DateTime } from "luxon";
import { DayOfWeek } from "./Controls/DayOfWeek";

export type IReportParams = {
    name:        string,
    activity:    ReportActivity;
    timeGrp:     TimeGrouping;
    curbZoneGrp: IZoneGroup;    //this one is also a problem
    startDate:   Date;
    endDate:     Date;
    daysOfWeek:  DayOfWeek[];     //this one is a problem
    timeRange:   Array<number>;
}

export class ReportParams implements IReportParams {
    constructor( public name:        string,
                 public activity:    ReportActivity,
                 public timeGrp:     TimeGrouping,
                 public curbZoneGrp: IZoneGroup,
                 public startDate:   Date,
                 public endDate:     Date,
                 public daysOfWeek:  DayOfWeek[],
                 public timeRange:   Array<number> ) {
        //nothing else to do...        
    }

    // was only needed for local storage
    // static fromJson( j: any ) {
    //     return {
    //         ...j,
    //         startDate: new Date( j.startDate ),
    //         endDate:   new Date( j.endDate   )
    //     };
    // }
}

export function getDefaultReport( startDate: string, endDate: string ): IReportParams {
    return new ReportParams( "Unique Sessions",
    ReportActivity.UniqueSessions, 
    TimeGrouping.ByDay,
    { type: CurbZoneGroupType.All, name: "Entire Deployment", curbZones: [] },
    DateTime.fromFormat( startDate, "yyyy-MM-dd" ).startOf( "month" ).toJSDate(),
    DateTime.fromFormat( endDate,   "yyyy-MM-dd" ).endOf(   "month" ).toJSDate(),
    getInitialDaysOfWeek(),
    [0, 24] );
}