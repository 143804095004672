import { DateTime } from "luxon";
import { useState } from "react";
import { ApiUrl } from "./Api/ApiUrl";
import { ICam } from "./Api/ICam";
import { IDepl } from "./Api/IDeployment";

export interface SupplyTabMapCamInfoProps {
    cam:  ICam;
    depl: IDepl;    
}

export function camLastUpdatedString(cam: ICam, deplTimezone: string){
    let lastUpdatedFromCamera = DateTime.fromISO( cam?.latest_information?.last_image_captured_at ?? "", { zone: "UTC" } );
    let lastUpdated           = undefined;
    if( lastUpdatedFromCamera.isValid ) {
        lastUpdatedFromCamera = lastUpdatedFromCamera.setZone( deplTimezone );
        lastUpdated           = lastUpdatedFromCamera.toRelative()!;
    }
    return lastUpdated
}

export function SupplyTabMapCamInfo( props: SupplyTabMapCamInfoProps ) {
    const cam          = props.cam;
    const depl         = props.depl;
    const deplTimezone = depl.info.tz;
    const [loaded, setLoaded] = useState<boolean>( false );
    // let lastUpdatedFromCamera = DateTime.fromISO( cam?.latest_information?.last_image_captured_at ?? "", { zone: "UTC" } );
    let lastUpdated           = camLastUpdatedString(cam, deplTimezone);
    // if( lastUpdatedFromCamera.isValid ) {
    //     lastUpdatedFromCamera = lastUpdatedFromCamera.setZone( deplTimezone );
    //     lastUpdated           = lastUpdatedFromCamera.toRelative()!;
    // }else{
    if (!lastUpdated){
        return <div className="rounded-5 p-3 info-window">
            <div className="text-center m-1">
                <div>
                    No Images Received Yet
                </div>
                <div className="mt-2">{cam.imei.toUpperCase()}&nbsp;
                    <span className="text-end" style={{ fontSize: "0.75em" }}>
                        {lastUpdated}
                    </span>
                </div>
            </div>
        </div>;
    }

    const latestTraceImgWithVision = cam.uuid ? <img style={{ borderRadius: "3px", width: "100%" }}
                                                     onLoad={ () => setLoaded( true ) }
                                                     src={ApiUrl.latestTraceImgWithVision( cam.uuid )} /> : <></>;
    return <div className="rounded-5 p-3 info-window" style={{ display: loaded ? "" : "none" }}>
        <div className="text-center m-1">
            <div>
                {latestTraceImgWithVision}
            </div>
            <div className="mt-2">{cam.imei.toUpperCase()}&nbsp;
                <br/>
                <span className="text-end" style={{ fontSize: "0.75em" }}>
                    {lastUpdated}
                </span>
            </div>
        </div>
    </div>;
}
