import {Dialog, DialogContent, DialogTitle, Tabs, Tab} from "@mui/material";
import { useEffect, useState } from "react";
import {ICurbZone, IZone} from "../../Api/IDeployment";
import { IPolicy } from "../../Api/IPolicy";
import { Options } from "../../Util/MeasureTypes";
import CreatePolicyTab from "./ZoneDialogTabs/CreatePolicyTab";
import SelectPolicyTab from "./ZoneDialogTabs/SelectPolicyTab";
import {Api} from "../../Api/Api";

export interface ZoneDialogPolicyProps {
    show:    boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    didCommit:  (zone: ICurbZone ) => void;
    zn:      ICurbZone;
    policy:  undefined | IPolicy;
}

enum ZoneDialogTab{
    select,
    create
}

export function ZoneDialogPolicy( props: ZoneDialogPolicyProps ) {
    const [zn,     setZn  ] = useState<ICurbZone>( props.zn );
    const [pols,   setPols] = useState<Array<IPolicy>>();
    const [tab, setTab] = useState<ZoneDialogTab>(ZoneDialogTab.select)


    useEffect( () => { //copy it if it changes while we are alive\
        console.log(`ZoneDialoguePolicy Zone Changed ${zn.name}`)
        if( props.zn ) setZn( props.zn )
    }, [props.zn] );

    const close  = () => { props.setShow( false ); }
    const cancel = () => close();
    const set    = ( obj: Options<IZone> ) => { setZn( zn => ({ ...zn, ...obj }) ); }
    async function commitPolicySelection(){
        const result = await Api.updateZone(zn)
        if (result.ok){
            props.didCommit( zn );
            close()
        }else{
            alert("Failed to update curbzone policy. Please try again. If it keeps failing contact help@vade.ai")
        }
    }

    async function commitCreatePolicy(){
        if( !zn ) {
            close()
            return
        }
        setTab(ZoneDialogTab.select)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handlePolicyChange = (policies: IPolicy[]) => {
        const selectedPolicyIds = policies.map((pol) => pol.curbPolicyId)
        setZn( {...zn, curb_policy_ids: selectedPolicyIds})
    }

    return <Dialog key={zn.curb_zone_id} open={props.show} onClose={cancel} fullWidth={true} maxWidth="md">
        <DialogTitle>{zn.name} Policies</DialogTitle>
        <DialogContent>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Existing"  />
                <Tab label="Create"  />
            </Tabs>
            <SelectPolicyTab value={tab} index={0}
                             zone={zn}
                             policiesChanged={handlePolicyChange}
                             cancel={cancel}
                             commit={commitPolicySelection}
            />
            <CreatePolicyTab value={tab} index={1}
                             cancel={cancel}
                             policyCreated={commitCreatePolicy}
            />
        </DialogContent>
    </Dialog>
}

