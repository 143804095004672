
export class DateUtil {

    static readonly tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    static toSmallDateStr( date: Date ): string {
        return date.toLocaleDateString( 'en-US' );
    }
}


export function getHoursBetweenDates(start: Date, end: Date): number {
    const millisecondsPerHour = 1000 * 60 * 60;
    const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
    const hours = Math.floor(diffInMilliseconds / millisecondsPerHour);
    return hours;
}