import { PolygonGeometry, LineStringGeometry } from "../Api/IDeployment";
import { LatLng } from "../Api/LatLng";

export type a = google.maps.LatLngLiteral;

export class MapUtil {
    static latLngToArr( latLng: LatLng ): [lng: number, lat: number] {
        return [latLng.lng, latLng.lat];
    }
}

//assumes only two points exist!
export function getPathCenter( path: google.maps.LatLngLiteral[] ): google.maps.LatLngLiteral {
    // if( path.length === 1 ) {
    //     return path[0];
    // }
    // if( path.length !== 2 ) {
    //     console.error( "path was an unexpected length" );
    // }
    let xc = ( path[0].lat + path[1].lat ) / 2.0;
    let yc = ( path[0].lng + path[1].lng ) / 2.0;
    return { lat: xc, lng: yc };
}

export function latLngFromPnt( p: Array<number> ): google.maps.LatLngLiteral {
    return { lat: p[1], lng: p[0] };
}

export function getPathFromCurbZone( gisLine: LineStringGeometry ) {
    return gisLine.coordinates.map( latLngFromPnt );
}

export function getPathForLot( gisLine: PolygonGeometry ) {
    return gisLine.coordinates[0].map( latLngFromPnt );
}