import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { FunctionComponent, useState } from "react";

interface ConfirmProps {
    title:        string;
    label:        string;
    cancelLabel?: string;
    commitLabel?: string;
    show:         boolean;
    setShow:      React.Dispatch<React.SetStateAction<boolean>>;
    commit:       () => void;
    children?:    any[];
}

//ugh I do not like this
export const Confirm: FunctionComponent<ConfirmProps> = ( props: ConfirmProps ) => {
    const close = () => {
        props.setShow( false );
    }

    const confirm = () => {
        close();
        props.commit();
    }
    
    const cancel = () => {
        close();
    }

    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.label}
            </DialogContentText>
            {props.children ?? [] }
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light"   onClick={cancel}>{props.cancelLabel}</MDBBtn>
            <MDBBtn color="danger" onClick={confirm}>{props.commitLabel}</MDBBtn>
        </DialogActions>
    </Dialog>
}

Confirm.defaultProps = {
    cancelLabel: "Cancel",
    commitLabel: "Confirm"
}