import { Polygon, Polyline, useGoogleMap } from "@react-google-maps/api";
import { ReactElement, useEffect, useState } from "react";

import { IUser } from "./Api/IUser";

//date stuff
import "react-calendar/dist/Calendar.css"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { getChartBaseUrl, getTableBaseUrl } from "./DomoUtil";
import { getDomoCardUrl } from "./DomoFilter";

import { AnalyticsMapData } from "./DemandMapData";
import { MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { ZoneEditor } from "./Controls/ZoneEditor";
import { getMeasureMapOptions } from "./Controls/MeasureMapOptions";
import { IDepl } from "./Api/IDeployment";
import { IZoneGroup } from "./IZoneGroup";
import { DemandTabReportTabFilterBar } from "./DemandTabReportTabFilterBar";
import { ReportTabParams } from "./IReportTabParams";
import { IDomoAuth, DomoApi } from "./Api/DomoApi";
import { MeasureMap } from "./Controls/MeasureMap";
import { getPathForLot, getPathFromCurbZone } from "./Util/MapUtil";
import { AnalyticsStayData, DomoQueryResp } from "./DemandStayData";
import { AnalyticsChartData } from "./DemandChartData";
import { colorize, ICurbZoneEntry, ILegend, legendize } from "./DemandMapUtil";
import { exportDomoQuery } from "./ExportUtil";

export interface DemandTabReportTabProps {
    user: IUser;
    depl: IDepl; //our deployment...do we need this?

    //curb zone groups
    curbZoneGroups: IZoneGroup[];
    updateCurbZoneGroup: ( cz: IZoneGroup ) => void;

    //reports
    reportParams: ReportTabParams;
    setReportParams: ( id: number, attr: string, val: any ) => void;
    saveReport: ( filts: ReportTabParams ) => string;
}

export function DemandTabReportTab( props: DemandTabReportTabProps ) {
    const params = props.reportParams;
    const [activeTab,      setActiveTab]      = useState( "chart" );
    const [domoAuth,       setDomoAuth]       = useState<IDomoAuth>();
    const [curbLines,      setCurbLines]      = useState<ICurbZoneEntry[]>( [] );
    const [legend,         setLegend]         = useState<ILegend[]>( [] );
    const [showInfo,       setShowInfo]       = useState<ICurbZoneEntry>();
    const [showZoneEditor, setShowZoneEditor] = useState<boolean>( false );
    const [gmap,           setGmap]           = useState<google.maps.Map>();

    //domo chart embed setup
    const chartUrl = getChartBaseUrl( params.activity, params.timeGrp );
    const domoChartUrl = getDomoCardUrl( params.timeGrp,
        chartUrl, 
        props.user.deploymentId,
        params.curbZoneGrp, 
        params.startDate,
        params.endDate, 
        params.daysOfWeek, 
        params.timeRange );

    //domo table embed setup
    const tableUrl = getTableBaseUrl( params.activity, params.timeGrp );
    const domoTableUrl = getDomoCardUrl( params.timeGrp,
        tableUrl,
        props.user.deploymentId,
        params.curbZoneGrp,
        params.startDate,
        params.endDate,
        params.daysOfWeek,
        params.timeRange );

    useEffect( () => {
        DomoApi.getAuthToken().then( resp => resp.json() ).then( json => {
            setDomoAuth( json );
        } );
    }, [] );

    function exportStays() {
        if( !domoAuth ) {
            return;
        }
        const prom = AnalyticsStayData.getStayRecs(
            domoAuth,
            props.user.deploymentId,
            params.curbZoneGrp,
            params.startDate,
            params.endDate,
            params.daysOfWeek,
            params.timeRange );
        if( !prom ) {
            return;
        }
        prom.then( r => r.json() ).then( ( data: DomoQueryResp ) => {
            exportDomoQuery( data, "export-stay-records.csv" );
        } );
    }

    function exportChartData() {
        if( !domoAuth ) {
            return;
        }
        const prom = AnalyticsChartData.getChartRecs(
            domoAuth,
            props.user.deploymentId,
            params.activity,
            params.curbZoneGrp,
            params.startDate,
            params.endDate,
            params.daysOfWeek,
            params.timeRange );
        if( !prom ) {
            return;
        }
        prom.then( r => r.json() ).then( ( data: DomoQueryResp ) => {
            exportDomoQuery( data, "export-chart-records.csv" );
        } );
    }

    useEffect( () => {
        if( !domoAuth ) { return; }
        let prom = AnalyticsMapData.getMapLines( domoAuth,
            props.user.deploymentId,
            params.activity,
            params.curbZoneGrp,
            params.startDate,
            params.endDate,
            params.daysOfWeek,
            params.timeRange );
        if( !prom ) {
            return;
        }
        //else
        prom.then( r => r.json() ).then( data => {
            const curbEntries: ICurbZoneEntry[] = [];
            for( let i = 0; i < data.rows.length; i++ ) {
                const curb  = data.rows[i][0];
                const val   = data.rows[i][1];
                const name  = data.rows[i][2];
                const units = data.rows[i][3];
                curbEntries.push( {
                    curbZoneName: name,
                    curbZoneId:   curb,
                    value:        val,
                    color:        "#000000",
                    units:        units
                } );
            } 
            const coloredEntries = colorize( curbEntries );
            const leg = legendize( curbEntries );
            setCurbLines( coloredEntries );
            setLegend( leg );
            resizeMap( coloredEntries );
        } );
    }, [domoAuth,
        params.activity,
        params.curbZoneGrp,
        params.startDate,
        params.endDate,
        params.daysOfWeek,
        params.timeRange] );

    let mapCurbZones: Array<ReactElement> = [];
    let pnts: Array<google.maps.LatLngLiteral> = []
    if( curbLines.length != 0 ) {
        mapCurbZones = curbLines.map( ( cz, i ) => {
            let curbZone = props.depl.curbZones.find( cz2 => cz.curbZoneId == cz2.curb_zone_id );
            if( !curbZone ) {
                return <></>;
            }
            if( curbZone.curb_zone_type == "parking_lot" ) {
                const path = getPathForLot( curbZone.geometry );
                pnts = pnts.concat( path );
                return <Polygon
                    key={`poly-${i}`}
                    options={{
                        fillColor: cz.color,
                        fillOpacity: 0.5,
                        strokeWeight: 5,
                        strokeColor: cz.color
                    }}
                    path={path}
                    onMouseOver={ e => setShowInfo( cz )        }
                     onMouseOut={ e => setShowInfo( undefined ) }
                        onClick={ e => setShowInfo( cz )        }
                    draggable={false} />
            } //otherwise it's a line
            const path = getPathFromCurbZone( curbZone.gis_line );
            pnts = pnts.concat( path );
            return <Polyline
                key={`pline-${i}`}
                path={path}
                options={{
                    strokeWeight: 5,
                    strokeColor: cz.color,
                    zIndex: 1
                }}
                onMouseOver={e => setShowInfo( cz )}
                onMouseOut={e => setShowInfo( undefined )}
                onClick={e => setShowInfo( cz )}
                draggable={false} />;
        } );
    }

    let infoWind = <></>;
    if( showInfo ) {
        const curbZone = props.depl.curbZones.find( cz2 => showInfo.curbZoneId === cz2.curb_zone_id );
        if( curbZone ) {
            infoWind = <div className="rounded-5 p-3 bg-black" style={{
                filter: "drop-shadow(30px 30px 10px #aaa7)",
                color: "#bbb",
                position: `absolute`,
                top: `7px`,
                left: '7px'
            }}>
                <div className="text-center">
                    <div style={{
                        fontFamily: "stolzl",
                        fontSize: "3.5em",
                        lineHeight: "1.2em",
                        color: showInfo.color
                    }}>{showInfo.value}{showInfo.units}</div>
                    <div>{params.activity}</div>
                    <div style={{ fontSize: "0.75vw" }}>{showInfo.curbZoneName}</div>
                </div>
            </div>;
        }
    }

    const handleTabClick = ( value: string ) => {
        if( value === activeTab ) {
            return;
        }
        setActiveTab( value );
    };

    const revLegend = legend.slice().reverse()
    const legendMarkup = <div className="rounded-5" style={{
        filter:         "drop-shadow(30px 30px 10px #aaa7)",
        color:          "#000",
        position:       `absolute`,
        top:            `60px`,
        right:          '0px',
        fontSize:       "1.1em",
        borderCollapse: "separate",
        borderSpacing:  "0 4px"
    }}>
        <div>
            {revLegend.map( ( el, i ) => {
                const prev = revLegend?.[i + 1]?.threshold ?? 0;
                return <div className="m-1" style={{ display: "table-row", backgroundColor: el.color }}>
                    <div className="text-end ps-3" style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        display: "table-cell"
                    }}>{prev.toFixed( 0 )}{el.unit}</div>
                    <div className="text-end ps-2" style={{ display: "table-cell" }}>-</div>
                    <div className="text-end px-2" style={{ display: "table-cell" }}>{el.threshold.toFixed( 0 )}{el.unit}</div>
                </div>
            } )}
        </div>
    </div>;

    function getPointsForAllCurbLines( curbLines: ICurbZoneEntry[] ) {
        let pnts: google.maps.LatLngLiteral[] = [];
        for( let curbEntry of curbLines ) {
            let curbZone = props.depl.curbZones.find( cz => curbEntry.curbZoneId == cz.curb_zone_id );
            if( !curbZone ) {
                console.error( `couldn't find curb zone w name ${curbEntry.curbZoneName}` );
                continue;
            }
            if( curbZone.curb_zone_type === "parking_lot" ) {
                const path = getPathForLot( curbZone.geometry );
                pnts = pnts.concat( path );
                continue;
            }
            const path = getPathFromCurbZone( curbZone.gis_line );
            pnts = pnts.concat( path );
        }
        return pnts;
    }

    function resizeMap( curbLines: ICurbZoneEntry[] ) {
        if( !gmap ) { return; }
        const pnts = getPointsForAllCurbLines( curbLines );
        let bounds = new google.maps.LatLngBounds();
        for( let p of pnts ) {
            bounds.extend( p );
        }
        gmap.fitBounds( bounds );
    }

    const containerStyle = { width: "100%", height: "100%" };
    function Content() {
        return <div className="tab-demand-body">
            <DemandTabReportTabFilterBar
                reportParams={params}
                setReportParams={props.setReportParams}
                curbZoneGroups={props.curbZoneGroups}
                showZoneEditor={showZoneEditor}
                setShowZoneEditor={setShowZoneEditor}
                saveReport={props.saveReport}
                exportStayRecs={ filts => {
                    exportStays();
                    return "Success!";
                }}
                exportChartRecs={ filts => {
                    exportChartData();
                    return "Success!";
                }} />
            <div className="tab-demand-content">
                <MeasureMap
                    onLoad={ ( g ) => setGmap( g ) }
                    options={getMeasureMapOptions()}
                    center={props.depl.extras.loc}
                    mapContainerStyle={containerStyle}
                    zoom={14}>
                    {infoWind}
                    {mapCurbZones}
                    {legendMarkup}
                </MeasureMap>
                <div className="tab-demand-chart-and-table">
                    <MDBTabs>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick( "chart" )} active={activeTab === "chart"}>
                                <img src="/img/icon-analytics.svg" width="20" /> Chart
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleTabClick( "table" )} active={activeTab === "table"}>
                                <img src="/img/icon-zones.svg" width="20" /> Table
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                    <MDBTabsContent className="fullheight">
                        <MDBTabsPane className="fullheight" show={activeTab === "chart"}>
                            <iframe src={domoChartUrl}
                                width={"100%"}
                                height={"100%"}
                                marginHeight={0}
                                marginWidth={0}
                                frameBorder={0}></iframe>
                        </MDBTabsPane>
                        <MDBTabsPane className="fullheight" show={activeTab === "table"}>
                            <iframe src={domoTableUrl}
                                width={"100%"}
                                height={"100%"}
                                marginHeight={0}
                                marginWidth={0}
                                frameBorder={0}></iframe>
                        </MDBTabsPane>
                    </MDBTabsContent>
                </div>
            </div>
        </div>;
    }

    if( showZoneEditor ) {
        return <ZoneEditor curbZoneGroup={params.curbZoneGrp}
            updateCurbZoneGroup={props.updateCurbZoneGroup}
            depl={props.depl}
            user={props.user}
            setShowZoneEditor={setShowZoneEditor} />;
    }
    return Content();
}
