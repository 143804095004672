
export class DomoApiUrl {
    static cardData( cardId: string ) {
        return `https://public.domo.com/cards/${cardId}/datasources`
    }
    
    static dataset( datasetId: string ) {
        return `https://api.domo.com/v1/datasets/query/execute/${datasetId}`;
    }

    static getOAuthToken() {
        return `https://api.domo.com/oauth/token?grant_type=client_credentials&scope=data`;
    }
}