
export enum ReportActivity {
    PeakOccupancy  = "Peak Occupancy", 
    UniqueSessions = "Unique Sessions", 
    DwellTime      = "Dwell Time", 
    AvgUtilization = "Avg. Utilization"
}

export const reportActivityOptions = [
    ReportActivity.PeakOccupancy,
    ReportActivity.UniqueSessions,
    ReportActivity.DwellTime,
    ReportActivity.AvgUtilization
];