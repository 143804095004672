import {TimeUnit} from "./IDeployment";

export interface IRate {
    rate: number;  // in cents
    rate_unit: TimeUnit;
    // rate_unit_period?: "rolling" | "calendar"
    // increment_duration?: number  //If specified, this is the smallest number of rate_units a user can pay for
    // increment_amount?: number //If specified, the rate for this space is rounded up to the nearest increment of this amount, specified in the same currency units as rate
    start_duration?: number  //The number of rate_units the vehicle must have already been present in the Curb Zone before this rate starts applying. If not specified, this rate starts when the vehicle arrives.
    end_duration?: number  //The number of rate_units after which the rate stops applying, If not specified, this rate ends when the vehicle departs.
}

export function defaultRate(): IRate {
    return {
        rate: 200,
        rate_unit: TimeUnit.Hour,
        start_duration: undefined,
        end_duration: undefined
    };
}

function formatCentsToDollar(cents: number): string {
    const dollars = cents / 100;
    return dollars.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function rateToTextPrimary(rate: IRate): string{
    const dollarValue = formatCentsToDollar(rate.rate)
    let retVal = `${dollarValue} / ${rate.rate_unit}`
    return retVal
}
