import { X, XCircle } from "heroicons-react";
import { useState } from "react";

interface ExitBtnProps {
    onClick: () => void;
}

export function ExitBtn( props: ExitBtnProps ) {
    const [hover, setHover] = useState<boolean>( false );

    let btn = <X height={15} style={{ pointerEvents: "none" }} />
    if( hover ) {
        btn = <XCircle style={{ pointerEvents: "none" }} height={15} />;
    }
    
    return <span
        style={{ position: "relative", top: "-1.5px" }}
        onMouseEnter={ () => { setHover( true  ); } }
        onMouseLeave={ () => { setHover( false ); } }
        onClick={( e ) => {
            e.stopPropagation();
            props.onClick();
        }}>
        {btn}
    </span>
}