import {GoogleMap, GoogleMapProps, InfoWindow, Marker, Polyline} from "@react-google-maps/api";
import { ReactNode, useEffect, useState } from "react";
import { ICam } from "./Api/ICam";
import { IZone, IDepl, INewZone, LineStringGeometry, ZoneType } from "./Api/IDeployment";
import { LatLng } from "./Api/LatLng";
import { Setter } from "./Util/MeasureTypes";
import { RealtimeEntry } from "./Api/RealtimeEntry";
import {camLastUpdatedString, SupplyTabMapCamInfo} from "./SupplyTabMapCamInfo";
import { SupplyTabMapToolbar } from "./SupplyTabMapToolbar";
import { getCamLocation, ZoneWindow, getStrokeWeight, Zones, curbLineOpts, junkGeo } from "./SupplyTabMapUtil";
import { SupplyTabMapTool } from "./SupplyTabMapTool";
import { ZoneDialogNew } from "./Controls/ZoneDialogNew";
import { latLngFromPnt, MapUtil } from "./Util/MapUtil";
import { getMeasureMapOptions } from "./Controls/MeasureMapOptions";
import { Api } from "./Api/Api";
import {getHoursBetweenDates} from "./Util/DateUtil";
import {Tooltip} from "@mui/material";

export interface SupplyTabMapProps extends GoogleMapProps {    
    depl:               IDepl;

    zoom:               number;
    setZoom:            Setter<number>;

    loc:                LatLng;
    setLoc:             Setter<LatLng>;

    containerStyle:     React.CSSProperties;
    cams:               ICam[];
    zones:              IZone[];
    realtimeData:       RealtimeEntry[];

    hoverZone:          IZone | undefined;
    setHoverZone:       Setter<IZone | undefined>;
    zoneHover:          ( zn: IZone | undefined ) => void;
    zoneDblClick:       ( zn: IZone    ) => void;
    zoneCreate:         ( zn: INewZone ) => void;    
    updateCurbZoneLine: ( zoneId: string, gisLine: LineStringGeometry ) => void;
}

export function getCurbZoneColor( rt: RealtimeEntry, enabled: boolean ) {
    let color = enabled ? "#4E66FF" : "#95A3FF";
    if( rt ) {
        color = enabled ? rt.color : "#95A3FF";
    }
    return color;
}

function getCamPins( cams: ICam[], setShowCamInfo: Setter<ICam | undefined> , showCam: ICam | undefined, depl: IDepl) {
    let camPins: any = <></>;
    camPins = cams.map( ( cam, i ) => {
        let iconPin = "/img/map-pin-modern-vade-v-black.svg"
        let isOldImage = false
        let hoursSinceLastCapture = undefined
        let lastImageTime = undefined
        if (cam.latest_information?.last_image_captured_at){
            lastImageTime = new Date(cam.latest_information.last_image_captured_at)
            hoursSinceLastCapture = getHoursBetweenDates(lastImageTime, new Date())
            isOldImage = hoursSinceLastCapture > 24
            if (isOldImage){
                iconPin = "/img/map-pin-modern-vade-v-black-warning_1.svg"
            }
        }
        const isThisCam = cam.uuid === showCam?.uuid
        const showInfoWindow = isOldImage && isThisCam && showCam

        return <Marker
                key={`marker-${i}`}
                icon={{ url: iconPin,
                    scaledSize: new google.maps.Size(45, 45) }}
                position={ getCamLocation( cam )}
                options={{ zIndex: i}}
                draggable={false}
                onMouseOver={ e => setShowCamInfo( cam ) }
                onMouseOut={  e => setShowCamInfo( undefined ) } >
                { showInfoWindow  &&  (
                    <InfoWindow>
                        <h6>{`Last image from ${camLastUpdatedString(cam, depl.info.tz)}`}</h6>
                    </InfoWindow>
                )}
        </Marker>
    } );
    return camPins;
}

function toGLatLngLiteral( latLng: LatLng ): google.maps.LatLngLiteral {
    return { lat: latLng.lat,
             lng: latLng.lng };
}

function toLatLng( latLng: google.maps.LatLng ): google.maps.LatLngLiteral {
    return { lat: latLng.lat(),
             lng: latLng.lng() };
}

export interface ToolState {
    addCurb:         Array<LatLng>;
    addCurbPreview:  null | LatLng;
    editCurb:        null | IZone;
    editCurbPreview: Array<LatLng>;
    addLot:          Array<LatLng>;
    editLot:         null | IZone;
}

const initToolState: ToolState = {
    addCurb:         [],
    addCurbPreview:  null,
    editCurb:        null,
    editCurbPreview: [],
    addLot:          [],
    editLot:         null
};

export function SupplyTabMap( props: SupplyTabMapProps ) {
    const [showCams, setShowCams] = useState<boolean>( false );
    const [showCam,  setShowCam ] = useState<ICam>();
    const [tool,     setTool    ] = useState<SupplyTabMapTool>( SupplyTabMapTool.View );
    const [toolSt,   setToolSt  ] = useState<ToolState>( initToolState );
    
    //create new cz
    const [showNew,  setShowNew ] = useState<boolean>( false );
    const [newCz,    setNewCz   ] = useState<INewZone>();

    function onKeyDownHandler( e: KeyboardEvent ): void {
        if( e.key === "Escape" ) { setToView(); }        
        return;
    }

    useEffect( () => {
        clearToolState();
    }, [tool] );

    useEffect( () => {
        window.addEventListener( "keydown", onKeyDownHandler );
        return () => { 
            window.removeEventListener( "keydown", onKeyDownHandler );
        }
    }, [] );

    const {
        depl,
        zoom,
        setZoom,
        loc,
        setLoc,
        containerStyle,
        cams,
        zones,
        realtimeData,
        hoverZone,
        setHoverZone,
        zoneHover,
        zoneDblClick,
        zoneCreate,
        updateCurbZoneLine,
        children,
        ...googleMapProps
    } = { ...props };

    //my state changers
    function doAddCurb1( e: google.maps.MapMouseEvent ) {
        if( !e.latLng ) { return; }
        setToolSt( { ...toolSt, addCurb: [ ...toolSt.addCurb, toLatLng( e.latLng! ) ] } );
    }

    function doAddCurb2( e: google.maps.MapMouseEvent ) {
        if( !e.latLng ) { return; }
        const newSt = {
            ...toolSt,
            addCurb:        [ ...toolSt.addCurb, toLatLng( e.latLng! ) ],
            addCurbPreview: null
        };
        setToolSt( st => ({ ...st, newSt }) );
        
        let coords = newSt.addCurb.map( p => [p.lng, p.lat] );
        const newZone: INewZone = {
            name:            "",
            street_name:     "",
            user_zone_id:    "",
            geometry:        { type: "Polygon",    coordinates: junkGeo }, 
            gis_line:        { type: "LineString", coordinates: coords  },    
            is_user_defined: true,
        };
        onCreate( newZone );
    }
    
    function doSetAddCurbPreview( e: google.maps.MapMouseEvent ) {
        setToolSt( { ...toolSt, addCurbPreview: toLatLng( e.latLng! ) } );
    }

    function onCreate( cz: INewZone ) {
        setNewCz( cz );
        setShowNew( true );
    }

    function setToView() {
        setTool( SupplyTabMapTool.View );
        setToolSt( { ...initToolState } );
    }
    
    function clearToolState() {
        setToolSt( { ...initToolState } );
    }

    //event handling infrastructure
    function clickHandler( e:google.maps.MapMouseEvent, zn: IZone | undefined ) {
        if( tool === SupplyTabMapTool.DrawCurbZone ) {
            if( !e.latLng ) { return; }
            if( toolSt.addCurb.length === 0 ) { doAddCurb1( e ); }
            if( toolSt.addCurb.length === 1 ) { doAddCurb2( e ); }
        }
        if( tool === SupplyTabMapTool.EditCurbZone ) {
            if( !zn ) { return; } //they didn't click a zone to edit
            if( zn.curb_zone_type === ZoneType.ParkingLot ) { return; }
            if( !zn.is_user_defined                       ) { return; }
            setToolSt( st => ({ ...st,
                                editCurb:        zn,
                                editCurbPreview: zn.gis_line.coordinates.map( p => latLngFromPnt( p ) ),
                            }) );
        }
        return;
    }

    function moveHandler( e: google.maps.MapMouseEvent ) {
        if( tool === SupplyTabMapTool.DrawCurbZone ) {
            if( !e.latLng ) { return; }
            if( toolSt.addCurb.length === 1 ) { doSetAddCurbPreview( e ); }
        }
        return;
    }

    //styling
    const strokeWeight   = getStrokeWeight( zoom );
    const curbZoneMarkup = <Zones zones={zones.filter( zn => zn.archived !== true )}
                                  realtimeData={realtimeData} 
                                  strokeWeight={strokeWeight}
                                  zoneHover={setHoverZone}
                                  zoneClick={ (e, zn) => clickHandler( e, zn ) }
                                  zoneDblClick={zoneDblClick}
                                  showZone={hoverZone}
                                  setShowZone={setHoverZone} />
    let addCurbZoneMarkup = <></>;
    if( toolSt.addCurb.length > 0 ) {
        const arr = [ ...toolSt.addCurb ];
        if( toolSt.addCurbPreview ) {
            arr.push( toolSt.addCurbPreview );
        }
        addCurbZoneMarkup = <Polyline path={arr.map( el => toGLatLngLiteral( el ))}
                                      options={curbLineOpts( "blue", strokeWeight, false )}
                                      draggable={false} />
    }

    //if there is a curb for editing
    let moveMarkers: ReactNode = <></>;
    if( toolSt.editCurb ) {
        moveMarkers = toolSt.editCurbPreview.map( ( p, i ) => {
            return <Marker key={`a${i}`}
                           draggable={ true }
                           position={ toGLatLngLiteral( p ) }
                           icon={{ url: `/img/circle-orange.svg`, 
                                   scaledSize: new google.maps.Size( 12, 12 ),
                                   anchor:     new google.maps.Point( 6, 6  )
                                }}
                           onDragEnd={ e => {
                                if( !e.latLng ) { return; }
                                const pnts = [...toolSt.editCurbPreview];
                                pnts[i] = toLatLng( e.latLng );
                                const znId = toolSt.editCurb?.curb_zone_id!;
                                const newLine: LineStringGeometry = { type: "LineString", coordinates: pnts.map( el => MapUtil.latLngToArr( el ) ) };
                                Api.updateCurbZoneGeometry( znId, newLine );
                           }}
                           onDrag={ e => {
                               if( !e.latLng ) { return; }
                               const pnts = [...toolSt.editCurbPreview];
                               pnts[i] = toLatLng( e.latLng ); //i do not like mutable arrays...sam-i-am
                               const newSt = { ...toolSt, editCurbPreview: pnts };
                               const znId = toolSt.editCurb?.curb_zone_id!;
                               const newLine: LineStringGeometry = { type: "LineString", coordinates: pnts.map( el => MapUtil.latLngToArr( el ) ) };
                               updateCurbZoneLine( znId, newLine );
                               setToolSt( newSt );
                               Api.updateCurbZoneGeometry( znId, newLine );
                        } } />
        } );
    }

    let editCurbPreview: ReactNode = <></>;
    if( toolSt.editCurbPreview ) {
        const arr = toolSt.editCurbPreview;
        editCurbPreview = <Polyline path={ arr.map( el => toGLatLngLiteral( el ) ) }
                                    options={curbLineOpts( "blue", strokeWeight, false ) }
                                    draggable={false} />
    }

    const camPins = showCams ? getCamPins( props.cams, setShowCam, showCam, depl ) : undefined;
    let infoWind: React.ReactNode = <></>;
    if( hoverZone ) {
        const realtime = realtimeData.find(
            el => el.curb_zone_id === hoverZone.curb_zone_id );
        infoWind = <ZoneWindow
                        realtime={realtime}
                        hover={hoverZone}
                        depl={props.depl} />;
    }
    if( showCam ) {
        infoWind = <SupplyTabMapCamInfo cam={showCam} depl={depl} />
    }

    return <GoogleMap
        mapContainerStyle={containerStyle}
        options={getMeasureMapOptions()}
        zoom={props.zoom}
        center={loc}
        onClick={ e => clickHandler( e, undefined ) }
        onMouseMove={moveHandler}
        {...googleMapProps}>
            {curbZoneMarkup}
            {camPins}
            {addCurbZoneMarkup}
            <SupplyTabMapToolbar showCams={showCams}
                                 setShowCams={setShowCams}
                                 tool={tool}
                                 setTool={setTool} />
            {infoWind}
            {moveMarkers}
            {editCurbPreview}
            <ZoneDialogNew
                   show={showNew} 
                   setShow={setShowNew}
                   cz={newCz}
                   commit={ ( cz ) => {
                       zoneCreate( cz );
                       setToView();
                   } }  />
    </GoogleMap>;
}
