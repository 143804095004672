import {DateTime} from "luxon";
import {DayOfWeek} from "../Controls/DayOfWeek";
import timeSpanEditTable from "../Controls/PolicyUtils/TimespanControls/TimeSpanEditTable/TimeSpanEditTable";

export interface IApiTimeSpan {
    start_date?:               string;
    end_date?:                 string;
    days_of_week?:             DayOfWeek[];
    days_of_month?:            number[];
    months?:                   number[];
    time_of_day_start?:        string; //hour of day from 0-24
    time_of_day_end?:          string; //hour of day from 0-24, must be > time_of_day_start
    designated_period?:        string;
    designated_period_except?: boolean;
}

export interface ITimeSpan {
    startDate?:                DateTime;  // If unspecified, the Time Span applies to all matching periods arbitrarily far in the past. See note below for more details.
    endDate?:                  DateTime;  // If unspecified, the Time Span applies to all matching periods arbitrarily far in the future. See note below for more details.
    daysOfWeek?:               DayOfWeek[];
    daysOfMonth?:              number[];
    months?:                   number[];
    timeOfDayStart?:           number; //hour of day from 0-24
    timeOfDayEnd?:             number; //hour of day from 0-24, must be > timeOfDayStart
    designatedPeriod?:         string;
    designatedPeriodExcept?:   boolean;
}

export function convertToNewITimeSpan( oldTimeSpan: IApiTimeSpan ): ITimeSpan {

    function parseHourFromTimeString(timeString?: string): number | undefined {
        if (!timeString) return undefined
        const [hour] = timeString.split(":");
        return parseInt(hour, 10);
    }


    const newTimeSpan: ITimeSpan = {
      startDate:               oldTimeSpan.start_date && DateTime.fromISO(oldTimeSpan.start_date) || undefined,
      endDate:                 oldTimeSpan.end_date && DateTime.fromISO(oldTimeSpan.end_date) || undefined,
      daysOfWeek:              oldTimeSpan.days_of_week,
      daysOfMonth:             oldTimeSpan.days_of_month,
      months:                  oldTimeSpan.months,
      timeOfDayStart:          parseHourFromTimeString(oldTimeSpan.time_of_day_start),
      timeOfDayEnd:            parseHourFromTimeString(oldTimeSpan.time_of_day_end),
      designatedPeriod:        oldTimeSpan.designated_period,
      designatedPeriodExcept:  oldTimeSpan.designated_period_except,
    };  
    return newTimeSpan;
}

export function convertToIApiTimeSpan( timespan: ITimeSpan ): IApiTimeSpan {
    let cleanTimeStart = timespan.timeOfDayStart ?? 0
    if (cleanTimeStart < 0) cleanTimeStart = 0
    let cleanTimeEnd = timespan.timeOfDayEnd ?? 23
    if (cleanTimeEnd > 23) cleanTimeEnd = 23
    const newTimeSpan: IApiTimeSpan = {
        start_date:                timespan.startDate?.toISODate(),
        end_date:                  timespan.endDate?.toISODate(),
        days_of_week:              timespan.daysOfWeek,
        days_of_month:             timespan.daysOfMonth,
        months:                    timespan.months,
        time_of_day_start:         timespan.timeOfDayStart && `${cleanTimeStart}:00` || undefined,
        time_of_day_end:           timespan.timeOfDayEnd && `${cleanTimeEnd}:00` || undefined,
        designated_period:         timespan.designatedPeriod && String(timespan.designatedPeriod) || undefined,
        designated_period_except:  timespan.designatedPeriodExcept
    };
    return newTimeSpan;
}


export function defaultTimeSpan(): ITimeSpan{
    return {
        startDate: undefined,
        endDate: undefined,
        daysOfWeek: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
        timeOfDayStart: 0,
        timeOfDayEnd: 23
    }
}