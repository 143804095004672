
export enum InputHandler {
    CurbZoneClick,
    CurbZoneDoubleClick,
    CurbZoneHover,
    CameraHover
}

//alternative design
export enum SupplyTabMapTool {
    View,
    DrawCurbZone,
    DrawParkingLot,
    EditCurbZone,
    EditParkingLot
}

export function getToolStr( t: SupplyTabMapTool ) {
    if( t === SupplyTabMapTool.View           ) { return "View"; }
    if( t === SupplyTabMapTool.DrawCurbZone   ) { return "Draw Curb Zone"; }
    if( t === SupplyTabMapTool.EditCurbZone   ) { return "Edit Curb Zone"; }
    if( t === SupplyTabMapTool.DrawParkingLot ) { return "Draw Parking Lot"; }
    if( t === SupplyTabMapTool.EditParkingLot ) { return "Edit Parking Lot"; }
    return "Err!";
}
