import React, {useState} from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {DayOfWeek} from "../../../DayOfWeek";

interface ToggleDaysProps {
    initalValue?: DayOfWeek[]
    onSelectionChange: (selectedDays: DayOfWeek[]) => void;
}

const ToggleDays: React.FC<ToggleDaysProps> = ({initalValue, onSelectionChange }) => {
    const defaultDays = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]
    const [days, setDays] = useState<DayOfWeek[]>(initalValue ?? defaultDays);

    const handleToggle = (event: React.MouseEvent<HTMLElement>, newDays: DayOfWeek[]) => {
        setDays(newDays);
        onSelectionChange(newDays);
    };

    return (
        <>
            <ToggleButtonGroup
                size="small"
                aria-label="Days of the week"
                value={days}
                onChange={handleToggle}
                sx={{ '& .MuiToggleButton-root': { borderRadius: '50%' } }} // Make the buttons circular
            >
                {Object.values(DayOfWeek).map((day) => (
                    <ToggleButton
                        key={day}
                        value={day}
                        aria-label={day}
                        sx={{
                            width: '40px', // Set the width of each button
                            height: '40px', // Set the height of each button
                            color: 'primary.main', // Use the system's primary color
                            '&.Mui-selected': {
                                backgroundColor: 'primary.main', // Use the system's primary color when selected
                                color: 'primary.contrastText', // Use the contrast text color for better visibility
                            },
                        }}
                    >
                        {day.charAt(0).toUpperCase()}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </>
    );
};

export default ToggleDays;
