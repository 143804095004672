import { DateTime } from "luxon";
import React, { createRef, useEffect, useState } from "react";
import { ApiUrl } from "../Api/ApiUrl"
import { IViolation } from "../ComplianceTabUtil";

interface FrameImgProps {
    obj:        IViolation;
    style?:     React.CSSProperties;
    className?: string;
}

export const sum = ( arr: Array<number> ) => arr.reduce( ( ps: number, a: number ) => ps + a, 0 );

export function centroid( pnts: Array<Array<number>> ) {
    let xs = sum( pnts.map( p => p[0] ) );
    let ys = sum( pnts.map( p => p[1] ) );
    return [xs / pnts.length, ys / pnts.length];
}

export default function FrameImg( props: FrameImgProps ) {
    const [canvasRef]   = useState<React.RefObject<HTMLCanvasElement>>( createRef<HTMLCanvasElement>() );
    const [img, setImg] = useState<HTMLImageElement>();
    const fontIncr      = 0;
    
    useEffect( () => {
        processImage();
    }, [props.obj.startImg] );

    async function processImage() {
        let bgImage = new Image();
        bgImage.src = props.obj.startImg;
        await new Promise<void>( ( resolve ) => {
            bgImage.onload  = ( _e ) => { return resolve(); };
            bgImage.onerror = ( _e ) => { return resolve(); };
        } );
        setImg( bgImage );
    }

    function drawBoundingBoxes( ps:   number[][], 
                                ctx:  CanvasRenderingContext2D ) {
        if( ps.length === 0 ) {
            return;
        }
        ctx.beginPath();
        ctx.moveTo( ps[0][0], ps[0][1] );
        for ( let i = 1; i < ps.length; i++ ) {
            ctx.lineTo( ps[i][0], ps[i][1] );
        }
        ctx.lineTo( ps[0][0], ps[0][1] );
        ctx.stroke();
    }

    function setTextStyle( ctx: CanvasRenderingContext2D, 
                           fontName:    string = "Monaco",
                           fontSize:    number = 10,
                           fillColor:   string = "black", 
                           strokeColor: string = "black",
                           strokeWidth: number = 1,
                           alpha:       number = 1.0 ) {
        ctx.font        = `${fontSize}pt ${fontName}`;
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function setDrawStyle( ctx: CanvasRenderingContext2D,
                           fillColor: string = "black",
                           strokeWidth: number = 1,
                           strokeColor: string = "black",
                           alpha: number = 1.0 ) {
        ctx.fillStyle   = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth   = strokeWidth;
        ctx.globalAlpha = alpha;
    }

    function drawCentroids( obj: IViolation, ctx: CanvasRenderingContext2D ) {
        const cent = centroid( obj.bbox );
        if( !cent || cent.length === 0 ) {
            return;
        }
        const [x, y] = [cent[0], cent[1]];
        
        //draw centroid
        setDrawStyle( ctx, "black", 5, "white" );
        ctx.beginPath();
        ctx.arc( x, y, 2, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fill();

        //label centroid
        // setTextStyle( ctx, "Monaco", 8 + fontIncr, "white", "black", 4 );
        // let vehicleType = obj.classLabel;
        // if( !vehicleType ) {
        //     vehicleType = "";
        // }
        // const shortVehicleId = obj.objId.toString();
        // const text = shortVehicleId + " " + vehicleType;
        // ctx.strokeText( text, x + 8, y + 8 );
        // ctx.fillText  ( text, x + 8, y + 8 );
    }

    function drawBoxes( obj: IViolation, ctx: CanvasRenderingContext2D ) {
        setDrawStyle( ctx, "white", 4, "white", 1.00 );
        drawBoundingBoxes( obj.bbox, ctx );
    }

    function drawGroundPolygons( obj: IViolation, ctx: CanvasRenderingContext2D ) {
        let strokeColor = "white";
        setDrawStyle( ctx, "white", 2, strokeColor, 1.00 );
        drawBoundingBoxes( obj.groundPoly, ctx );
    }

    function drawTimecode( desc: string, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement ) {
        setTextStyle  ( ctx, "Monaco", 20, "white", "black", 4 );
        ctx.strokeText( desc, 10, canvas.height - 10 );
        ctx.fillText  ( desc, 10, canvas.height - 10 );
    }
    
    const draw = ( background: HTMLImageElement | undefined ) => {
        if( !background ) {
            return;
        }
        const canvas = canvasRef.current;
        if( !canvas ) {
            return;
        }
        canvas.height = background.naturalHeight;
        canvas.width  = background.naturalWidth;
        const ctx = canvas.getContext( "2d", { alpha: false } );
        if( !ctx ) {
            return;
        }
        ctx.drawImage     ( background, 0, 0 );
        drawBoxes         ( props.obj, ctx );
        drawGroundPolygons( props.obj, ctx );
        drawCentroids     ( props.obj, ctx );
        // drawTimecode   ( props.obj.startTime.toISO(), ctx, canvas );
        return;
    };
    
    return <>
        <canvas ref      ={canvasRef} 
                className={props?.className}
                style    ={props?.style} />
        { draw( img ) }
    </>;
}


