import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { ICurbZone } from "../Api/IDeployment";

export interface ZoneDialogAdvProps {
    show:       boolean;
    setShow:    React.Dispatch<React.SetStateAction<boolean>>;
    deleteZone: ( cz: ICurbZone ) => void;
    cz:         undefined | ICurbZone;
}

export function ZoneDialogAdv( props: ZoneDialogAdvProps ) {
    const [cz, setCz] = useState<ICurbZone>();

    useEffect( () => { if( props.cz ) { setCz( props.cz ); } }, [] );
    useEffect( () => {
        if( props.cz && cz !== props.cz ) {
            setCz( props.cz );
        }
    }, [props.cz] );

    const close  = () => { props.setShow( false ); }
    const cancel = () => close();

    if( !cz ) { return <></>; }

    const deleteZone = () => {
        close();
        props.deleteZone( cz );
    }
    
    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>Delete Zone</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete <div className="d-inline-block alert alert-danger py-1 p-2 rounded-2">{cz.name}</div>?
            </DialogContentText>
            <input style={{ display: "none" }} type="text" name="fakeusernameremembered"/>
            <input style={{ display: "none" }} type="password" name="fakepasswordremembered"/>
            <br />
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light"  onClick={cancel}>Cancel     </MDBBtn>
            <MDBBtn color="danger" onClick={deleteZone}>Delete Zone</MDBBtn>
        </DialogActions>
    </Dialog>
}