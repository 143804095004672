
export enum Activity {
    Parking     = 'parking',
    NoParking   = 'no parking',
    Loading     = 'loading',
    NoLoading   = 'no loading',
    Unloading   = 'unloading',
    NoUnloading = 'no unloading',
    Stopping    = 'stopping',
    NoStopping  = 'no stopping',
    Travel      = 'travel',
    NoTravel    = 'no travel',
}

export const allActivities: Activity[] = [
    Activity.Parking,
    Activity.NoParking,
    Activity.Loading,
    Activity.NoLoading,
    Activity.Unloading,
    Activity.NoUnloading,
    Activity.Stopping,
    Activity.NoStopping,
    Activity.Travel,
    Activity.NoTravel,
];


