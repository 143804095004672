import {TabPanelProps} from "../../ZoneDialogUtil";
import PolicyEditor from "../PolicyEditor";
import {useContext, useState} from "react";
import {GlobalContext} from "../../../App";
import {defaultPolicy, IPolicy} from "../../../Api/IPolicy";
import {MDBBtn} from "mdb-react-ui-kit";
import {DialogActions} from "@mui/material";
import {Api} from "../../../Api/Api";
import { v4 as uuidv4 } from 'uuid';


export interface CreatePolicyTabProps extends TabPanelProps{
    cancel: () => void
    policyCreated: (policy: IPolicy) => void
}

export function CreatePolicyTab(props: CreatePolicyTabProps) {
    const {cancel, policyCreated, children, value, index, ...other } = props;
    const depl = useContext(GlobalContext)!.depl;

    const [policy, setPolicy] = useState<IPolicy>(defaultPolicy(depl.uuid))
    const handlePolicyChange = (policy: IPolicy) => {
        console.log(JSON.stringify(policy))
        setPolicy(policy)
    }

    async function handleCreatePolicy(){
        policy.curbPolicyId = uuidv4()
        const response = await Api.createPolicy(policy)
        if (response.ok){
            depl.policies.push(policy)
            policyCreated(policy)
        }else{
            alert("Failed to save policy. Please check your connection and try again. If it continutes failing, contact help@vade.ai")
        }
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                // This is where the meat goes
                <div>
                    <PolicyEditor startingPolicy={policy} onChange={handlePolicyChange}/>
                    <DialogActions>
                        <MDBBtn color="light" onClick={cancel}>Cancel</MDBBtn>
                        <MDBBtn color="primary" onClick={handleCreatePolicy}>Submit</MDBBtn>
                    </DialogActions>
                </div>
            )}
        </div>
    );
}

export default CreatePolicyTab