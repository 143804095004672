import {DateTime} from "luxon";
import {UUID} from "../Util/MeasureTypes";
import {convertToIApiRule, convertToIRule, defaultRule, IApiRule, IRule} from "./IRule";
import {convertToIApiTimeSpan, convertToNewITimeSpan, defaultTimeSpan, ITimeSpan} from "./ITimeSpan";
import { v4 as uuidv4 } from 'uuid';

const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export interface IApiPolicy {
    curb_policy_id:    UUID;
    published_date:    DateTime;
    priority:          number;
    rules:             IApiRule[];
    time_spans?:       ITimeSpan[];
    deployment_uuid:   string;
    name:              string;
    archived:          boolean;
    // data_source_operator_id?: UUID[];
}

export interface IPolicy {
    curbPolicyId:      UUID;
    pubDate:           DateTime;
    priority:          number;
    rules:             IRule[];     //I think I removed the optionality here
    timeSpans:         ITimeSpan[]; //removed optionality, will always have an empty array at a minimum
    // data_source_operator_id?: UUID[];
    deployment_uuid:   string;
    name:              string;
    archived:          boolean;
}

export function convertToIVadePolicy( apiPolicy: IApiPolicy ): IPolicy {
    const vadePolicy: IPolicy = {
        curbPolicyId: apiPolicy.curb_policy_id,
        pubDate:      apiPolicy.published_date,
        priority:     apiPolicy.priority,
        rules:        apiPolicy.rules.map( convertToIRule ),
        timeSpans:    (apiPolicy.time_spans ?? []).map( convertToNewITimeSpan ),
        deployment_uuid: apiPolicy.deployment_uuid,
        name:           apiPolicy.name,
        archived:       apiPolicy.archived
    };
    return vadePolicy;
}

export function convertToIAPIPolicy( policy: IPolicy): IApiPolicy {
    const vadePolicy: IApiPolicy = {
        curb_policy_id: policy.curbPolicyId,
        published_date: policy.pubDate,
        priority: policy.priority,
        rules: policy.rules.map(convertToIApiRule),
        time_spans: policy.timeSpans.map(convertToIApiTimeSpan),
        deployment_uuid: policy.deployment_uuid,
        name:            policy.name,
        archived:        policy.archived
    };
    return vadePolicy;
}

export function defaultPolicy(deployment_uuid: string): IPolicy{
    return {
        curbPolicyId: uuidv4(),
        pubDate: DateTime.fromJSDate(new Date()),
        priority: 1,
        rules: [defaultRule()],
        timeSpans: [defaultTimeSpan()],
        deployment_uuid: deployment_uuid,
        name: "My New Policy",
        archived: false
    }
}
