import { useEffect, useReducer, useState } from "react";
import { HeatmapLayer, InfoWindow, Marker } from "@react-google-maps/api";
import { IViolationRaw } from "./Api/IViolation";
import { getInitialDaysOfWeek } from "./DomoUtil";
import { ComplianceTabFilterBar } from "./ComplianceTabFilterBar";
import { IUser } from "./Api/IUser";
import { IViolation, processViolation, vioColor, vioColorOnly, vioDisp, ViolationType } from "./ComplianceTabUtil";
import { getMeasureMapOptions } from "./Controls/MeasureMapOptions";
import { DateTime } from "luxon";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { IDomoAuth, DomoApi } from "./Api/DomoApi";
import { MeasureMap } from "./Controls/MeasureMap";
import { IDepl } from "./Api/IDeployment";
import FrameImg from "./Controls/FrameImg";
import { DomoQueryResp } from "./DemandStayData";
import { exportDomoQuery } from "./ExportUtil";

interface ComplianceTabProps {
    user: IUser;
    depl: IDepl;
}

function parseVioRec( row: any[] ): IViolationRaw {
    return {
        violation:                row[0],
        location:                 row[1],
        start_image_url:          row[2],
        session_start_time_local: row[3],
        session_end_time_local:   row[4],
        dwell_time_minutes:       row[5],
        vehicle_uuid:             row[6],
        vehicle_bbox:             JSON.parse( row[7] ),
        vehicle_ground_plane:     JSON.parse( row[8] )
    };
}

export function ComplianceTab( props: ComplianceTabProps ) {
    const [violationType, setViolationType] = useState<ViolationType>(ViolationType.All);
    const [startDate,     setStartDate    ] = useState<Date>( DateTime.fromFormat( props.depl.extras.defaultFilterStartDate, "yyyy-MM-dd" ).toJSDate() );
    const [endDate,       setEndDate      ] = useState<Date>( DateTime.fromFormat( props.depl.extras.defaultFilterEndDate,   "yyyy-MM-dd" ).toJSDate() );
    
    const daysEnabled = getInitialDaysOfWeek();
    const [daysOfWeek, setDaysOfWeek      ] = useState<DayOfWeek[]>( daysEnabled );
    const [timeRange,  setTimeRange       ] = useState<number[]>( [0, 24] );
    const [zoom,       setZoom            ] = useState<number>( 13 );
     
    const [domoAuth,    setDomoAuth       ] = useState<IDomoAuth>();
    const [violations,  setViolations     ] = useState<IViolation[]>();
    const [gMap,        setGMap           ] = useState<google.maps.Map>();
    const [showInfo,    setShowInfo       ] = useState<IViolation>();
    const [pnt,         setPnt            ] = useState<google.maps.LatLng>();
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect( () => {
        DomoApi.getAuthToken().then( resp => resp.json() ).then( json => {
            setDomoAuth( json );
        } );
    }, [] );


    //filter by violation type
    let filts: Array<string> = [];
    if( violationType !== ViolationType.All ) {
        filts.push( `violation LIKE '%${violationType}%'` );
    }

    //deployment id filter
    filts.push( `filter_deployment_id = '${props.user.deploymentId}'` );

    //date range filter
    let sd = DateTime.fromJSDate( startDate ).startOf( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
    let ed = DateTime.fromJSDate( endDate   ).endOf  ( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
    filts.push( `(filter_time > '${sd}' AND filter_time < '${ed}')` );

    //days of week filter
    let dayList = daysOfWeek.map( d => `'${d}'` ).join(',');
    filts.push( `filter_day_of_week IN (${dayList})` );
    
    //hour of day filter
    filts.push( `filter_hour_of_day >= ${timeRange[0]} AND filter_hour_of_day <= ${timeRange[1]}`);

    //generate where clause
    let whereClause = "";
    if( filts.length !== 0 ) {
        whereClause = "WHERE " + filts.join( " AND " );
    }

    const domoQuery: string = `SELECT violation                  AS "Violation",
                                      location                   AS "Location", 
                                      start_image_url            AS "Start Image Url",
                                      session_start_time_local   AS "Start Time",
                                      session_end_time_local     AS "End Time",
                                      dwell_time_minutes         AS "Dwell Time",
                                      vehicle_uuid               AS "Vehicle Id",
                                      vehicle_bbox               AS "Vehicle Bounding Box",
                                      vehicle_ground_plane       AS "Vehicle Ground Plane Polygon"
                               FROM table ${whereClause}`; //generate sql select stmt
    useEffect( () => {
        if( domoAuth ) {
            DomoApi.getAllViolations( domoAuth, domoQuery ).then( resp => resp.json() )
                                                           .then( json => {
                setViolations( 
                    json.rows.map( ( row: Array<any> ) => {
                        let rawVio: IViolationRaw = parseVioRec( row );
                        if( rawVio.location === "" ) { //check the location field
                            // console.error( `a violation had an empty location: ${row}` );
                            return null;
                        }
                        return processViolation( rawVio );
                    } ).filter( ( el: null | IViolation ) => el !== null ) );
            } );
        }
    }, [domoAuth, violationType, startDate, endDate, daysOfWeek, timeRange] );
    
    function exportViolations() {
        if( !domoAuth ) { return; }
        const prom = DomoApi.getAllViolations( domoAuth, domoQuery );
        if( !prom ) { return; }
        prom.then( r => r.json() ).then( ( data: DomoQueryResp ) => {
            exportDomoQuery( data, "export-noncompliant-stay-records.csv" );
        } );
    }

    //totals window
    let count = violations?.length ?? 0;
    let visibleViolations: IViolation[] = [];
    const viewPort = gMap?.getBounds();
    if( gMap && violations && viewPort ) {
        const latLngs = violations.map( vio => vio.location );
        visibleViolations = violations.filter( v => viewPort.contains( v.location ) );
        count = latLngs.map( latLng => viewPort.contains( latLng ) ? 1 : 0 ).reduce( (x: number, y: number) => x + y, 0 );
    }
    if( count === 0 ) {
        count = violations?.length ?? 0;
    }

    let totalsWind = <div className="rounded-5 p-3 bg-black" style={{
        filter: "drop-shadow(30px 30px 10px #aaa7)",
        color       : "#bbb",
        position    : "absolute",
        top         : "7px",
        left        : "7px"
        }}>
        <div className="text-center">
            <div style={{ fontFamily:     "stolzl",
                          fontSize:     "3.5em",
                          lineHeight:   "1.2em",
                          color: vioColor(violationType, false) }}>{count}</div>
            <div>{vioDisp( violationType ) }<br />Violations</div>
        </div>
    </div>;

    //draw some lines
    let heatMapLayer: any = <></>;
    if( violations && gMap && zoom < 19 ) {
        let latLngs = violations.map( vio => vio.location );
        heatMapLayer = <HeatmapLayer
            options={{ radius: 30, dissipating: true, maxIntensity: (violations.length / 10.0) }}
            data={ latLngs } />
    }
    let markerLayer: any = <></>;
    if( visibleViolations && zoom >= 19 ) {
        markerLayer = visibleViolations.map( (vio, i) => {
            const sel = vio === showInfo;
            const z = sel ? 1 : 0;
            return <Marker key={i}
                           zIndex={z}
                           position={vio.location}
                           icon={{ url: `/img/circle-${vioColor( vio.violation, sel )}.svg`, 
                                   scaledSize: new google.maps.Size(12, 12) }}
                           onMouseOver={ () => setShowInfo( vio ) } 
                           onMouseOut={ () => setShowInfo( undefined ) } /> } );
    }

    function changeZoom() {
        const zm = gMap?.getZoom();
        if( !zm ) {
            return;
        }
        setShowInfo( undefined );
        setZoom( zm );
    }

    function onDragEnd() {
        const center = gMap?.getCenter();
        if( !center ) {
            return;
        }
        forceUpdate();
        setPnt( center );
    }

    function convertPoint( gMap: google.maps.Map, latLng: google.maps.LatLng ) {
        const proj     = gMap.getProjection()!;
        const bnds     = gMap.getBounds()!;
        const zm       = gMap.getZoom()!;
        var topRight   = proj.fromLatLngToPoint( bnds.getNorthEast() )!;
        var bottomLeft = proj.fromLatLngToPoint( bnds.getSouthWest() )!;
        var worldPoint = proj.fromLatLngToPoint( latLng )!;
        var scale      = Math.pow( 2, zm );
        return new google.maps.Point( ( worldPoint.x - bottomLeft.x ) * scale, ( worldPoint.y - topRight.y ) * scale );
    }

    function timeLabel( vio: IViolation ) {
        const fmt = "h:mma";
        if( vio.startTime.toFormat( fmt ) === vio.endTime.toFormat( fmt ) ) {
            return <td>{vio.startTime.toFormat( fmt )}</td>
        }
        return <>
            <td>{vio.startTime.toFormat( fmt )}</td>
            <td>-</td>
            <td>{vio.endTime.toFormat( fmt )}</td>
        </>;
    }
    
    let infoWind = <></>;
    if( showInfo && gMap ) {
        let pnt = convertPoint( gMap, showInfo.location );
        infoWind = <div className="bg-black px-3 py-2 rounded-5" style={{ 
                                 fontFamily:    "stolzl",
                                 color:         `white`,
                                 position:      "absolute",
                                 left:          `${pnt?.x}px`,
                                 top:           `${pnt?.y}px`,
                                 pointerEvents: "none",
                                 transform:     "translate(-105%, -105%)",
                                 width:         "350px" }}>
                                     <div>{vioDisp( showInfo.violation )}</div>
                <div>
                    <div className="text-center"
                        style={{
                            fontSize: "3.5em",
                            lineHeight: "1.0em",
                        }}>
                        <FrameImg style={{ width: "100%" }} obj={showInfo} />
                    </div>
                    <table cellPadding={1} className="mt-1">
                        <tr>
                            <td>{showInfo.startTime.toLocaleString()}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            {timeLabel( showInfo )}
                        </tr>
                    </table>
                </div>
            </div>
    }

    const containerStyle = { width: "100%", height: "100%" };
    return <div className="tab-compliance">
        <ComplianceTabFilterBar 
            violationType={violationType} setViolationType={setViolationType}
            daysOfWeek={daysOfWeek}       setDaysOfWeek={setDaysOfWeek}
            startDate={startDate}         setStartDate={setStartDate}
            timeRange={timeRange}         setTimeRange={setTimeRange}
            endDate={endDate}             setEndDate={setEndDate}
            exportViolations={ () => { 
                exportViolations();
                return "Success!";   
            }} />
        <MeasureMap
            onLoad={ m => setGMap( m ) }
            options={getMeasureMapOptions()}
            center={props.depl.extras.loc}
            mapContainerStyle={containerStyle}
            onZoomChanged={ () => changeZoom() }
            onDrag={ () => onDragEnd() }
            zoom={zoom}>
            {totalsWind}
            {heatMapLayer}
            {markerLayer}
            {infoWind}
        </MeasureMap>
    </div>
}
