import {allDaysOfWeek, DayOfWeek} from "../../DayOfWeek";
import {ITimeSpan} from "../../../Api/ITimeSpan";

export function getDaysOfWeek(startDate: Date, endDate: Date) {
    const daysOfWeek = Object.values(DayOfWeek);
    let days: DayOfWeek[] = [];

    // Clone the startDate so we can modify it without affecting the original
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const dayOfWeek = daysOfWeek[currentDate.getDay()];
        if (!days.includes(dayOfWeek)) {
            days.push(dayOfWeek);
        }
        // Increment the current date by one day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
}


export function timeSpanToString(span: ITimeSpan){
    return `${span.timeOfDayStart} -> ${span.timeOfDayEnd} | ${span.daysOfWeek}`
}

export const defaultTimeSpanStartDate = () => new Date(1998, 3, 5)  // just need a full week to view, so heres my birthday week
export const defaultTimeSpanEndDate = () => new Date(1998, 3, 11)  // just need a full week to view, so heres my birthday week

export function dateFor(dayOfWeek: DayOfWeek){
    const startDate = defaultTimeSpanStartDate()
    const daysOfWeek = allDaysOfWeek
    const targetDayIndex = daysOfWeek.findIndex(day => day === dayOfWeek.toLowerCase());
    const currentDayIndex = startDate.getDay();
    const offset = targetDayIndex - currentDayIndex;

    startDate.setDate(startDate.getDate() + offset);
    return startDate;
}

export function getColorForNumber(number: number): string {
    const colors = [
        '#00ff00', // Green
        '#0000ff', // Blue
        '#ffff00', // Yellow
        '#ff00ff', // Magenta
        '#00ffff', // Cyan
        '#800080', // Purple
        '#ff0000', // Red

    ];

    const index = Math.abs(number) % colors.length;
    return colors[index];
}