import {enumToOptions} from "../Util/UIHelpers";

export enum VehicleType {
    Bicycle = 'bicycle',
    Bus = 'bus',
    CargoBicycle = 'cargo_bicycle',
    Car = 'car',
    Moped = 'moped',
    Motorcycle = 'motorcycle',
    Scooter = 'scooter',
    Truck = 'truck',
    Van = 'van',
}

export enum VehicleProperty {
    HandicapAccessible = 'handicap-accessible',
    HumanPowered       = 'human',
    ElectricAssist     = 'electric_assist',
    Electric           = 'electric',
    Combustion         = 'combustion',
    Autonomous         = 'autonomous',
}

export enum Purpose {
    Construction    = 'construction',
    Delivery        = 'delivery',
    EmergencyUse    = 'emergency_use',
    Freight         = 'freight',
    Parking         = 'parking',
    Permit          = 'permit',
    Rideshare       = 'rideshare',
    School          = 'school',
    ServiceVehicles = 'service_vehicles',
    SpecialEvents   = 'special_events',
    Taxi            = 'taxi',
    Utilities       = 'utilities',
    Vending         = 'vending',
    WasteManagement = 'waste_management',
}

export type UserClass = VehicleType | VehicleProperty | Purpose;

export function allUserClasses(): UserClass[]{
    return [
        ...Object.values(VehicleType),
        ...Object.values(VehicleProperty),
        ...Object.values(Purpose)
    ]
}

export function allUserClassesAsSelectOptions(): {label: string, options: {label: string, value: UserClass}[]}[]{
    return [
        {
            "label": "Vehicle Types",
            "options": enumToOptions(Object.values(VehicleType))
        },
        {
            "label": "Vehicle Properties",
            "options": enumToOptions(Object.values(VehicleProperty))
        },
        {
            "label": "Vehicle Purpose",
            "options": enumToOptions(Object.values(Purpose))
        }
    ]
}