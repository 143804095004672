import React, { useState } from "react";
import { IRate, rateToTextPrimary } from "../../../Api/IRate";
import { NumFld } from "../../ZoneDialogUtil";
import { Options } from "../../../Util/MeasureTypes";
import { Button, Grid, Typography } from "@mui/material";
import Select from "react-select";
import { enumToOption, enumToOptions } from "../../../Util/UIHelpers";
import { TimeUnit } from "../../../Api/IDeployment";
import CloseIcon from "@mui/icons-material/Close";

const DelButton = ({ onClick }: { onClick: () => void }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        onClick();
    };

    const buttonStyle = {
        minWidth: 0,
        width: "20px",
        height: "20px",
        borderRadius: "2px",
        backgroundColor: isHovered ? "rgb(253,164,155)" : "transparent",
        color: isHovered ? "red" : "inherit",
        cursor: "pointer",
        padding: 0,
        position: "absolute",
        top: "5px",
        right: "2px",
    };

    return (
        <Button
            sx={buttonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={(e) => {
                e.stopPropagation();
                handleClick();
            }}
        >
            <CloseIcon style={{ fontSize: "14px" }} />
        </Button>
    );
};

interface RateBadgeProps {
    rate: IRate;
    idx: number;
    rateChanged: (newRate: IRate, idx: number) => void;
    rateDeleted: (idx: number) => void;
}

const RateBadge: React.FC<RateBadgeProps> = ({
                                                 rate,
                                                 idx,
                                                 rateChanged,
                                                 rateDeleted,
                                             }) => {
    const [expanded, setExpanded] = useState(false);
    const rateUnitOptions = enumToOptions(Object.values(TimeUnit));

    const handleBadgeClick = (event: React.MouseEvent) => {
        setExpanded(!expanded);
    };

    const set = (options: Options<IRate>) => {
        const newRate: IRate = { ...rate, ...options };
        console.log("Rate: ", newRate);
        rateChanged(newRate, idx);
    };

    function handleRateUnitChange(
        selectedOption: { label: string; value: TimeUnit } | null
    ) {
        if (!selectedOption) return;
        set({ rate_unit: selectedOption.value });
    }

    const RateNonExpandedText = (props: { rate: IRate }) => {
        const mRate = props.rate;
        const mainRateText = rateToTextPrimary(mRate);
        const startDurrText =
            mRate.start_duration && `applies after ${mRate.start_duration} ${mRate.rate_unit}(s)`;
        const endDurrText =
            mRate.end_duration && `ends after ${mRate.end_duration} ${mRate.rate_unit}(s)`;
        return (
            <div>
                <Typography variant={"body2"}> {mainRateText} &nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                {mRate.start_duration && (
                    <Typography variant={"caption"}> {startDurrText} </Typography>
                )}
                {mRate.start_duration && mRate.end_duration && <br />}
                {mRate.end_duration && (
                    <Typography variant={"caption"}> {endDurrText} </Typography>
                )}
            </div>
        );
    };

    return (
        <div
            style={{
                display: "inline-block",
                margin: "5px",
                padding: "5px",
                backgroundColor: "lightgrey",
                cursor: "pointer",
                borderRadius: "5px",
                position: "relative",
                zIndex: expanded ? 9000 - idx : "auto",
            }}
            onClick={handleBadgeClick}
        >
            {!expanded && <DelButton onClick={() => rateDeleted(idx)} />}
            {!expanded && <RateNonExpandedText rate={rate} />}
            {expanded && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumFld
                            lbl="cost in cents"
                            val={rate.rate}
                            set={(v) => set({ rate: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        Time Unit
                        <div
                            onClick={(e) => e.stopPropagation()} // Need to wrap in div to keep click from propagating
                        >
                            <Select
                                isSearchable={true}
                                options={rateUnitOptions}
                                value={enumToOption(rate.rate_unit)}
                                onChange={(val) => handleRateUnitChange(val)}
                                menuPosition="fixed" // Set the position of the dropdown menu as fixed
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <NumFld
                            lbl="Starts After"
                            val={rate.start_duration}
                            opSet={(v) => set({ start_duration: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumFld
                            lbl="Ends After"
                            val={rate.end_duration}
                            opSet={(v) => set({ end_duration: v })}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default RateBadge;
