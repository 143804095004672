import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { ReactNode, useEffect, useState } from "react";
import { INewZone } from "../Api/IDeployment";
import { Action, Options } from "../Util/MeasureTypes";
import { StrFld } from "./ZoneDialogUtil";

export interface ZoneDialogNewProps {
    show:    boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    commit:  Action<INewZone>;
    cz:      undefined | INewZone;
} 

export function ZoneDialogNew( props: ZoneDialogNewProps ) {
    const [cz,     setCz] = useState<INewZone>();
    const [errs, setErrs] = useState<ReactNode>( <></> ); 

    useEffect( () => { if( props.cz ) { setCz( props.cz ); } }, [] );
    useEffect( () => { //copy it if it changes while we are alive
        if( props.cz && cz !== props.cz ) {
            setCz( props.cz );
        }
    }, [props.cz] )

    const close = () => {
        props.setShow( false );
    }

    const commit = () => {
        if( !cz ) { return; }
        if( cz.name.trim() === "" ) {
            setErrs( <div className="btn-warning rounded-2 px-2 py-1">Zone Name is required!</div> );
            return;
        }
        close();
        props.commit( cz );
    }
    
    const cancel = () => close();
    if( !cz ) {
        return <></>;
    }

    const set = ( obj: Options<INewZone> ) => { setCz( { ...cz, ...obj } ); }

    return <Dialog open={props.show} onClose={cancel} fullWidth={true}>
        <DialogTitle>Create Zone</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You can add more details later
            </DialogContentText>
            {errs}
            <input style={{ display: "none" }} type="text" name="fakeusernameremembered" />
            <input style={{ display: "none" }} type="password" name="fakepasswordremembered" />
            <StrFld lbl="Zone Name (Required)" val={cz.name}            set={ val => set( { name:         val } ) } />
            <StrFld lbl="User Zone Id (Optional)" val={cz.user_zone_id} set={ val => set( { user_zone_id: val } ) }   />
            <StrFld lbl="Street Name (Optional)" val={cz.street_name}   set={ val => set( { street_name:  val } ) }   />
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light"   onClick={cancel}>Cancel     </MDBBtn>
            <MDBBtn color="primary" onClick={commit}>Create Zone</MDBBtn>
        </DialogActions>
    </Dialog>
}