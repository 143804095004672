import { DateTime } from "luxon";
import { IDomoAuth, DomoApi } from "./Api/DomoApi";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { CurbZoneGroupType, IZoneGroup } from "./IZoneGroup";

export class AnalyticsChartData {

    static getChartRecs( domoAuth: IDomoAuth, 
                         deplId: string,
                         activity: string, 
                         curbZoneGrp: IZoneGroup,
                         startDate: Date, 
                         endDate: Date, 
                         daysOfWeek: DayOfWeek[], 
                         timeRange: number[] ) {
        //each domo dataset has been set up so that we can use the same where clause on each
        const where = this.whereClause( deplId, curbZoneGrp, startDate, endDate, daysOfWeek, timeRange );
        if( activity === "Dwell Time" ) {
            return DomoApi.datasetQuery( domoAuth,
                "dee06666-681c-421b-9690-f52535e3be91",
                `SELECT filter_time AS Time, curb_zone_name AS "Curb Zone", ROUND( "median-dwell", 0 ) AS "Median Dwell", ' min' AS Units FROM table ${where}` );
        }
        if( activity === "Unique Sessions" ) {
            return DomoApi.datasetQuery( domoAuth,
                "c536fba2-f11e-4e6c-a99e-8821c1eed4d7",
                `SELECT filter_time AS Time, curb_zone_name AS "Curb Zone", ROUND( "total-turnover", 0 ) AS "Total Turnover", '' AS Units FROM table ${where}` );
        }
        if( activity === "Avg. Utilization" ) {
            return DomoApi.datasetQuery( domoAuth,
                "c536fba2-f11e-4e6c-a99e-8821c1eed4d7",
                `SELECT filter_time AS Time, curb_zone_name AS "Curb Zone", ROUND( "avg-utilization" * 100.0, 0 ) AS "Average Utilization", '%' AS Units FROM table ${where}` );
        }
        if( activity === "Peak Occupancy" ) {
            return DomoApi.datasetQuery( domoAuth,
                "c536fba2-f11e-4e6c-a99e-8821c1eed4d7",
                `SELECT filter_time AS Time, curb_zone_name AS "Curb Zone", ROUND( "peak-occupancy" * 100.0, 0 ) AS "Peak Occupancy", '%' AS Units FROM table ${where}` );
        }
        return null;
    }

    static whereClause( deplId: string,
                        curbZoneGrp: IZoneGroup,
                        startDate: Date,
                        endDate: Date,
                        daysOfWeek: DayOfWeek[], 
                        timeRange: number[] ) {

        const filts: Array<string> = [];

        //deployement filter
        filts.push( `filter_deployment_id = '${deplId}'` );

        //curb zone filter
        if( curbZoneGrp && curbZoneGrp.type !== CurbZoneGroupType.All ) {
            const zoneList = curbZoneGrp.curbZones.map( cz => `'${cz}'` ).join( ', ' );
            filts.push( `filter_curb_zone IN (${zoneList})` )
        }

        //date filter
        let sd = DateTime.fromJSDate( startDate ).startOf( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        let ed = DateTime.fromJSDate( endDate   ).endOf  ( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        filts.push( `(filter_time > '${sd}' AND filter_time < '${ed}')` );

        //day of week filter
        const dayList = daysOfWeek.map( d => `'${d}'` ).join( ',' );
        filts.push( `filter_day_of_week IN (${dayList})` );

        //hour of day filter
        filts.push( `filter_hour_of_day >= ${timeRange[0]} AND filter_hour_of_day <= ${timeRange[1]}`);

        //generate where clause
        let whereCls = "";
        if( filts.length !== 0 ) {
            whereCls = "WHERE " + filts.join( " AND " ) + " ORDER BY filter_time ASC";
        }
        return whereCls;
    }
}
