import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import { useState } from "react";

interface CreateDeplDialogProps {
    show:         boolean;
    setShow:      React.Dispatch<React.SetStateAction<boolean>>;
    commit:       ( deplId: string, startDate: string, endDate: string ) => void;
} 

export function CreateDeplDialog( props: CreateDeplDialogProps ) {
    const [deplId,    setDeplId]    = useState( "" );
    const [startDate, setStartDate] = useState( "" );
    const [endDate,   setEndDate]   = useState( "" );

    const close = () => {
        //clear state
        props.setShow( false );
        setDeplId   ( "" );
        setStartDate( "" );
        setEndDate  ( "" );
    }

    const commit = () => {
        close();
        props.commit( deplId, startDate, endDate );
    }
    
    const cancel = () => close();

    return <Dialog open={props.show} onClose={cancel} fullWidth={false}>
        <DialogTitle>Create Deployment Record</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Creates a Deployment Record in Firestore
            </DialogContentText>
            <input style={{ display: "none"}} type="text"     name="fakeusernameremembered" />
            <input style={{ display: "none"}} type="password" name="fakepasswordremembered" />
            <TextField
                fullWidth
                margin    ="dense"
                label     ="Deployment Id"
                type      ="text"
                variant   ="standard"
                value     ={deplId}
                inputProps={{ autoComplete: "new-username" }}
                onKeyDown ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onChange={ (e) => setDeplId( e.target.value ) } />
            <TextField
                fullWidth
                margin      ="dense"
                label       ="Default Filter Start Date"
                type        ="text"
                variant     ="standard"
                value       ={startDate}
                autoComplete="none"
                inputProps  ={{ autoComplete: "new-password" }}
                onKeyDown   ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onChange={ (e) => setStartDate( e.target.value ) } />
            <TextField
                fullWidth
                margin      ="dense"
                label       ="Default Filter End Date"
                type        ="text"
                variant     ="standard"
                value       ={endDate}
                autoComplete="none"
                inputProps  ={{ autoComplete: "new-deplId" }}
                onKeyDown   ={ (e) => {
                    if( e.key === "Enter" ) {
                        e.stopPropagation();
                     }
                }}
                onChange={ (e) => setEndDate( e.target.value ) } />
        </DialogContent>
        <DialogActions>
            <MDBBtn color="light"   onClick={cancel}>Cancel</MDBBtn>
            <MDBBtn color="primary" onClick={commit}>Add Deployment</MDBBtn>
        </DialogActions>
    </Dialog>
}