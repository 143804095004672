import { Slider } from "@mui/material";
import { useState } from "react";
import { TimeRangeSliderUtil } from "./TimeRangeSliderUtil";

interface TimeRangeSliderProps {
    timeRange:    Array<number>;
    setTimeRange: ( timeRange: Array<number> ) => void;
}

export function TimeRangeSlider( props: TimeRangeSliderProps ) {
    const [value, setValue] = useState<number[]>( props.timeRange );
    
    const handleChange = ( ev: any, newValue: number | number[] ) => {
        setValue( newValue as number[] );
    };

    const commitChange = ( ev: any, newValue: number | number[] ) => {
        props.setTimeRange( newValue as number[] );
    };

    return <Slider valueLabelFormat={ (v, i) => TimeRangeSliderUtil.dispHour( v ) }
                   min={0} 
                   max={24}
                   value={value}
                   onChange={handleChange}
                   onChangeCommitted={commitChange}
                   valueLabelDisplay="auto" />
}