import React from "react";
import { IRule } from "../../../Api/IRule";
import {defaultRate, IRate} from "../../../Api/IRate";
import { Box } from "@mui/material";
import RateBadge from "./RateBadge";
import {AddButton} from "../../../Util/UIHelpers";
import { styled } from "@mui/system";

interface RateEditTableProps {
    rule: IRule;
    ratesDidChange?: (newRates: IRate[], idx: number) => void;
}

export const RateEditTable: React.FC<RateEditTableProps> = ({ rule, ratesDidChange }) => {
    const rates = rule.rate ?? [];

    function handleRateChange(newRate: IRate, idx: number) {
        const rCopy = [...rates];
        rCopy[idx] = newRate;
        ratesDidChange && ratesDidChange(rCopy, idx);
    }

    function handleRateDelete(idx: number) {
        let newRates = [...rates]
        newRates.splice(idx, 1);
        ratesDidChange && ratesDidChange(newRates, idx)
    }

    const addRate = () => {
        const newRate = defaultRate()
        let newRates = [...rates, newRate]
        ratesDidChange && ratesDidChange(newRates, 0)
    }

    return (
        <Box
            sx={{
                border: "1px solid lightgrey",
                borderRadius: 2,
                position: "relative",
                minHeight: "40px"
            }}
        >
            {rates?.map((rate, idx) => (
                <RateBadge
                    rate={rate}
                    idx={idx}
                    rateChanged={handleRateChange}
                    rateDeleted={handleRateDelete}
                    key={`rate_badge_${idx}`} />
            ))}

            <AddButton
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    m: "4px",
                    height: "30px",
                    width: "30px",
                }}
                onClick={addRate}
            />
        </Box>
    );
};
