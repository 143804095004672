import React from "react";

import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { Calendar, momentLocalizer, Event } from 'react-big-calendar'

import {IPolicy} from "../../../../Api/IPolicy";
import {ITimeSpan} from "../../../../Api/ITimeSpan";
import './TimeSpanViewCalendar.css'
import {
    dateFor,
    defaultTimeSpanEndDate,
    defaultTimeSpanStartDate,
    getColorForNumber,
} from "../TimeSpanHelpers";
import {DayOfWeek} from "../../../DayOfWeek";

export interface TimeSpanEditTablebProps{
    policy: IPolicy
    timeSpans: ITimeSpan[];
    onTimeSpanHover?: (newRules: ITimeSpan[], idx: number) => void
}

const TimeSpanViewCalendar:  React.FC<TimeSpanEditTablebProps>  = ({timeSpans , onTimeSpanHover, policy}) => {

    const defaultStartDate = defaultTimeSpanStartDate()
    const defaultEndDate = defaultTimeSpanEndDate()
    const localizer = momentLocalizer(moment)

    interface MyEvent extends Event{
        color: any
    }

    const timeSpantoEvents = (timeSpan: ITimeSpan, idx: number) => {
        let calEvents: MyEvent[] = []
        for (const rawDay of timeSpan.daysOfWeek ?? Object.values(DayOfWeek)){
            const day = dateFor(rawDay)
            const startHour = timeSpan.timeOfDayStart ?? 0
            const endHour = timeSpan.timeOfDayEnd ?? 23

            const start = new Date(day)
            const end = new Date(day)

            start.setHours(startHour)
            if (endHour >= 24){
                end.setHours(23)
                end.setMinutes(0)
            }else{
                end.setHours(endHour)
            }

            let calEvent: MyEvent = {
                    title: policy.name,
                    start: start,
                    end: end,
                    color: getColorForNumber(idx),
                }

            if (startHour === 0 && endHour > 23){
                calEvent.allDay = true
            }
            calEvents.push(calEvent)
        }
        // console.log("Cal Events", calEvents.map((ev) => `${ev.start} -> ${ev.end}`))
        return calEvents

    }

    const calEvents = () => {
        const allEvents = timeSpans.map((tSpan, idx) => timeSpantoEvents(tSpan, idx))
        const flattened = allEvents.flat()
        return flattened
    }

    const events = calEvents()

    const dayFormat = (date: Date, culture: any, localizer: any) => localizer.format(date, 'ddd', culture);

    return (
        <Calendar
            formats={{dayFormat}}
            view={"week"}
            step={120}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            defaultDate={defaultStartDate}
            toolbar={false}
            eventPropGetter={
                (event, start, end, isSelected) => {
                    let newStyle = {
                        backgroundColor: "lightgrey",
                        color: 'black',
                        borderRadius: "0px",
                        border: "none"
                    };

                    if (event.color){
                        newStyle.backgroundColor = event.color
                    }
                    return {
                        className: "",
                        style: newStyle
                    };
                }
            }
        />
    );
};

export default TimeSpanViewCalendar