import { DateTime } from "luxon";
import { DayOfWeek } from "./Controls/DayOfWeek";
import { TimeGrouping } from "./DomoUtil";
import { CurbZoneGroupType, IZoneGroup } from "./IZoneGroup";
import { IPFilter } from "./IPFilter";

export function getDomoCardUrl( timeGrp:     TimeGrouping, 
                                cardUrl:     string,
                                deplId:      string,
                                curbZoneGrp: IZoneGroup, 
                                startDate:   Date, 
                                endDate:     Date,
                                daysOfWeek:  DayOfWeek[], 
                                timeRange: number[] ) {
    let filters: IPFilter[];
    if( timeGrp === TimeGrouping.ByDay ) {
        filters = getDailyDomoFilters( deplId, curbZoneGrp, startDate, endDate, daysOfWeek, timeRange );
    }
    else {
        filters = getHourlyDomoFilters( deplId, curbZoneGrp, startDate, endDate, daysOfWeek, timeRange );
    }
    return getChartUrl( cardUrl, filters );
}

export function getChartUrl( cardUrl: string, filts: IPFilter[] ) {
    const filt = JSON.stringify( filts );
    return `${cardUrl}?pfilters=${filt}`;
}

export function getHourlyDomoFilters( deplId:     string,      curbZoneGrp: IZoneGroup, 
                                      startDate:  Date,        endDate:     Date, 
                                      daysOfWeek: DayOfWeek[], timeRange:   number[] ) {
    let filters: IPFilter[] = [];
    filters.push( { "column": "filter_deployment_id", "operand": "IN", "values": [deplId] } );
    if( curbZoneGrp && curbZoneGrp.type !== CurbZoneGroupType.All ) {
        const curbZones = curbZoneGrp.curbZones; 
        filters.push( { "column": "filter_curb_zone", "operand": "IN", "values": curbZones } );
    }
    if( startDate && endDate ) {
        let sd = DateTime.fromJSDate( startDate ).startOf( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        let ed = DateTime.fromJSDate( endDate   ).endOf  ( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        filters.push( { "column": "filter_time", "operand": "GREAT_THAN_EQUALS_TO", "values": [sd] } );
        filters.push( { "column": "filter_time", "operand": "LESS_THAN_EQUALS_TO",  "values": [ed] } );
    }
    if( daysOfWeek ) {
        const vals = daysOfWeek;
        filters.push( { "column": "filter_day_of_week", "operand": "IN", "values": vals } );
    }
    if( timeRange.length == 2 ) {
        filters.push( { "column": "filter_hour_of_day", "operand": "GREAT_THAN_EQUALS_TO", "values": [timeRange[0]] } );
        filters.push( { "column": "filter_hour_of_day", "operand": "LESS_THAN_EQUALS_TO",  "values": [timeRange[1]] } );
    }
    return filters;
}

export function getDailyDomoFilters( deplId:    string, curbZoneGrp: IZoneGroup, 
                                     startDate: Date,   endDate:     Date, daysOfWeek: DayOfWeek[], timeRange: number[] ) {
    let filters: IPFilter[] = [];
    filters.push( { "column": "filter_deployment_id", "operand": "IN", "values": [deplId] } );
    if( curbZoneGrp && curbZoneGrp.type !== CurbZoneGroupType.All ) {
        const curbZones = curbZoneGrp.curbZones; 
        filters.push( { "column": "filter_curb_zone", "operand": "IN", "values": curbZones } );
    }
    if( startDate && endDate ) {
        let sd = DateTime.fromJSDate( startDate ).startOf( "day" ).toFormat( "yyyy-MM-dd HH:mm" );
        let ed = DateTime.fromJSDate( endDate   ).endOf  ( "day" ).toFormat( "yyyy-MM-dd HH:mm" );

        //in the value of the column field, it's gross how domo is making me do this... -CAD Fri Jan 20, 2023
        filters.push( { "column": "filter_time", "operand": "GREAT_THAN_EQUALS_TO", "values": [sd] } ); 
        filters.push( { "column": "filter_time", "operand": "LESS_THAN_EQUALS_TO",  "values": [ed] } );
    }
    if( daysOfWeek ) {
        const vals = daysOfWeek;
        filters.push( { "column": "filter_day_of_week", "operand": "IN", "values": vals } );
    }
    return filters;
}